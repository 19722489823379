import React, { useContext, useEffect } from 'react';
import { Drawer, List, Typography } from 'antd';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { isEmpty } from 'lodash';
import { CargoFilterWidgetList } from '@platform/constants/cargoFilterWidgetList';
import { getCargoFilterDefaults } from '@utils/common/filters';
import { setQueryParam } from '@utils/location';
import { getCargoFiltersFromURL } from '@platform/utils';
import { CargoFilterWidget } from '@components/CargoFilterWidget';
import { CargoFilterURLEntry, CargoListFilterWidgetType } from '@beacon-types/cargo/cargoFilters';
import { Mode } from '@beacon-devops/graphql-typescript-client';
import { basePathAir } from '@platform/routes/paths';
import { CargoSearchContext } from '../CargoSearchProvider/context';
import {
  AddFilterIcon,
  CargoSearchFieldsWrapper,
  FilterListItem,
  FilterListWrapper,
  SelectedFiltersWrapper,
} from './styles';

const { Text } = Typography;

const CargoComplexSearch = () => {
  const {
    isOpen,
    toggleOpen,
    selectedFilters,
    addFilter,
    removeFilter,
    setCondition,
    setFieldValue,
    setSelectedFilters,
  } = useContext(CargoSearchContext);
  const location = useLocation();
  const history = useHistory();

  const cargoMode: Mode = location.pathname === basePathAir ? Mode.Air : Mode.Ocean;
  const CargoModeColumns = CargoFilterWidgetList.filter((filter) => filter.supportedModes?.includes(cargoMode));

  useEffect(() => {
    const serializeSelectedFilterValuesToURLSearch = (
      locationSearch: string,
      selectedFilters: CargoListFilterWidgetType[],
    ) => {
      const query = qs.parse(locationSearch);
      const selectedFiltersWithValues = selectedFilters.filter(
        (filter) => filter.currentValue && filter.currentValue.value && !isEmpty(filter.currentValue.value),
      );
      const selectedFiltersQueries: CargoFilterURLEntry[] = selectedFiltersWithValues.map((filter) => ({
        key: filter.key,
        currentValue: filter.currentValue,
      }));
      const selectedFiltersQueryString = isEmpty(selectedFiltersQueries)
        ? undefined
        : JSON.stringify(selectedFiltersQueries);
      const currentFilterQueryString = query.filters;

      if (selectedFiltersQueryString !== currentFilterQueryString) {
        const urlParams = {
          ...query,
          current: 1,
          filters: selectedFiltersQueryString,
        };
        setQueryParam(history, urlParams);
      }
    };

    serializeSelectedFilterValuesToURLSearch(location.search, selectedFilters);
  }, [selectedFilters, location.search]);

  useEffect(() => {
    setSelectedFilters(getCargoFiltersFromURL(location.search, cargoMode));
  }, []);

  return (
    <Drawer
      title='Cargo filters'
      placement='right'
      closable
      onClose={toggleOpen}
      visible={isOpen}
      width={450}
      style={{ position: 'absolute' }}
      data-testid='cargo-filters'
    >
      <CargoSearchFieldsWrapper data-testid='selected-filters'>
        {selectedFilters.map((filter, index) => {
          const key = index + filter.label;
          return (
            <SelectedFiltersWrapper key={key} isFirst={index === 0}>
              <CargoFilterWidget
                filterIndex={index}
                cargoFilter={filter}
                onClose={removeFilter}
                setValue={setFieldValue}
                setCondition={setCondition}
              />
            </SelectedFiltersWrapper>
          );
        })}
      </CargoSearchFieldsWrapper>
      <FilterListWrapper>
        <List
          dataSource={CargoModeColumns}
          renderItem={(item: CargoListFilterWidgetType) => {
            const alreadySelected = selectedFilters.find((selFil) => selFil.key === item.key);

            if (alreadySelected) {
              return null;
            }

            return (
              <FilterListItem
                key={item.label}
                onClick={() => {
                  addFilter({ ...item, currentValue: getCargoFilterDefaults(item) });
                }}
                style={{ cursor: 'pointer' }}
              >
                <div>
                  <Text style={{ fontWeight: 500 }}>{item.label}</Text>&nbsp;
                  <Text style={{ opacity: 0.5 }}>{item.labelSub}</Text>
                </div>
                <AddFilterIcon />
              </FilterListItem>
            );
          }}
        />
      </FilterListWrapper>
    </Drawer>
  );
};

export default CargoComplexSearch;
