import React, { FC, useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Row } from 'antd';
import { login } from '@state/user/actions';
import { AppState } from '@state/reduxTypes';
import { redirectToLogin } from '@services/Auth';

const ProtectRoute: FC<RouteProps> = (props) => {
  const dispatch = useDispatch();
  const { isAuthenticated, isLoading } = useSelector((state: AppState) => ({
    isAuthenticated: state.user.isAuthenticated,
    isLoading: state.user.loading,
  }));
  const isInitiating = isAuthenticated === undefined;

  useEffect(() => {
    dispatch(login());
  }, [dispatch]);

  useEffect(() => {
    if (!isInitiating && !isLoading && !isAuthenticated) {
      redirectToLogin();
    }
  }, [isInitiating, isAuthenticated, isLoading]);

  if (isLoading || isInitiating) {
    return (
      <Row align='middle' justify='center' style={{ height: '500px' }}>
        <Spin size='large' />
      </Row>
    );
  }

  if (isAuthenticated) {
    return <Route {...props} />;
  }

  return <></>;
};

export default ProtectRoute;
