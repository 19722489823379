import React, { useEffect, useState } from 'react';
import { Card, Collapse, Table } from 'antd';
import { ZoomInOutlined } from '@ant-design/icons';
import {
  CarrierShipment,
  CarrierShipmentTrackingStatus,
  Location,
  Cargo,
} from '@beacon-devops/shipment-tracker-client';
// Local imports
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { TitleBar, TitleContainer, TitleWithoutMargin } from './styles';
import {
  ScvEntityType,
  AddInspectionEntity,
  buildInspectLink,
  renderEntityItem,
  renderEntityItemDateTime,
  buildLocationLink,
} from './ScvCommanderEntityUtils';
import {
  getCargosByCarrierShipmentId,
  getLocationById,
} from '../../../services/ShipmentTrackerService/ShipmentTrackerService';

interface ScvCommanderEntityCarrierShipmentProps {
  entityId: string;
  entityData: CarrierShipment;
  addInspectionEntityFunc: AddInspectionEntity;
  backgroundColor: string;
}

/**
 * This component renders a Carrier Shipment given some data from Shipment Tracker. It will also query shipment tracker for child elements
 * like carriershipments which will also be rendered.
 */
export const ScvCommanderEntityCarrierShipment = (props: ScvCommanderEntityCarrierShipmentProps): JSX.Element => {
  const carrierShipment = props.entityData;

  // Data related to this shipment we want to show in the main display:
  const [originLocation, setOriginLocation] = useState<Location>();
  const [primaryLoadLocation, setPrimaryLoadLocation] = useState<Location>();
  const [primaryDischargeLocation, setPrimaryDischargeLocation] = useState<Location>();
  const [destinationLocation, setDestinationLocation] = useState<Location>();
  const [cargos, setCargos] = useState<Cargo[]>();

  // Fetch all the locations plus related cargos for the main display
  useEffect(() => {
    if (carrierShipment.originLocationId) {
      getLocationById(carrierShipment.originLocationId).then((location) => setOriginLocation(location));
    }
    if (carrierShipment.primaryLoadLocationId) {
      getLocationById(carrierShipment.primaryLoadLocationId).then((location) => setPrimaryLoadLocation(location));
    }
    if (carrierShipment.primaryDischargeLocationId) {
      getLocationById(carrierShipment.primaryDischargeLocationId).then((location) =>
        setPrimaryDischargeLocation(location),
      );
    }
    if (carrierShipment.destinationLocationId) {
      getLocationById(carrierShipment.destinationLocationId).then((location) => setDestinationLocation(location));
    }
    // Cargo list:
    if (carrierShipment.id) {
      getCargosByCarrierShipmentId(carrierShipment.id).then((cargoList) => setCargos(cargoList));
    }
  }, [carrierShipment]);

  // Table column definitions
  const cargoListColumns = [
    {
      title: 'Container Number',
      dataIndex: ['container', 'containerNumber'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: <ZoomInOutlined />,
      dataIndex: 'id',
      render: (cargoId: string) => buildInspectLink(ScvEntityType.cargo, cargoId, props.addInspectionEntityFunc),
    },
  ];

  function renderCargoList() {
    return <Table dataSource={cargos} columns={cargoListColumns} rowKey='id' />;
  }

  /**
   * Renders the carriershipment card plus any extra information for this entity
   * @param carriershipment CarrierShipment entity from Shipment Tracker
   */
  function render(carrierShipment: CarrierShipment): JSX.Element {
    function renderTrackingStatusStatus(trackingStatus: CarrierShipmentTrackingStatus): JSX.Element {
      return (
        <>
          {renderEntityItem('Tracking Status', trackingStatus.status)}
          {renderEntityItemDateTime('Stopped Tracking At', trackingStatus.stoppedTrackingAt)}
        </>
      );
    }

    return (
      <>
        <TitleBar>
          <TitleContainer>
            <TitleWithoutMargin level={5}>Carrier Shipment</TitleWithoutMargin>
          </TitleContainer>
        </TitleBar>
        <Card>
          {renderEntityItemDateTime('Created At', carrierShipment.createdAt)}
          {renderEntityItemDateTime('Last Updated At', carrierShipment.updatedAt)}
          {renderEntityItem('Primary Mode', carrierShipment.mode)}
          {carrierShipment.trackingStatus ? renderTrackingStatusStatus(carrierShipment.trackingStatus) : ''}
          {renderEntityItem(
            'Origin',
            buildLocationLink(originLocation, carrierShipment.originLocationId, props.addInspectionEntityFunc),
          )}
          {renderEntityItem(
            'Primary Load',
            buildLocationLink(
              primaryLoadLocation,
              carrierShipment.primaryLoadLocationId,
              props.addInspectionEntityFunc,
            ),
          )}
          {renderEntityItem(
            'Primary Discharge',
            buildLocationLink(
              primaryDischargeLocation,
              carrierShipment.primaryDischargeLocationId,
              props.addInspectionEntityFunc,
            ),
          )}
          {renderEntityItem(
            'Destination',
            buildLocationLink(
              destinationLocation,
              carrierShipment.destinationLocationId,
              props.addInspectionEntityFunc,
            ),
          )}
        </Card>

        <TitleBar>
          <TitleContainer>
            <TitleWithoutMargin level={5}>Cargo List</TitleWithoutMargin>
          </TitleContainer>
        </TitleBar>
        <Collapse>
          <CollapsePanel header='Cargos' key='cargos' style={{ background: props.backgroundColor }}>
            {renderCargoList()}
          </CollapsePanel>
        </Collapse>
      </>
    );
  }

  return <div>{carrierShipment ? render(carrierShipment) : '...loading'}</div>;
};
