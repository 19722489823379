import React, { Dispatch, SetStateAction } from 'react';
import { Button, Modal } from 'antd';
import { DeleteText } from './styles';

interface Props {
  isOpen: boolean;
  containerNumber: string;
  customer: string;
  setDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  onDelete: () => void;
  onCancel: () => void;
}

export const ContainerDeleteModal = ({ isOpen, containerNumber, customer, onCancel, onDelete }: Props) => {
  return (
    <Modal
      title='Delete a container'
      okButtonProps={{
        danger: true,
      }}
      onCancel={onCancel}
      footer={[
        <Button key='back' onClick={onCancel}>
          Cancel
        </Button>,
        <Button data-testid='container-modal-delete-button' key='submit' type='primary' danger onClick={onDelete}>
          Delete
        </Button>,
      ]}
      visible={isOpen}
    >
      <p>
        You are deleting container <strong>{containerNumber}</strong> from <strong>{customer}</strong>. Once this
        container is deleted it can&apos;t be recovered.
      </p>
      <p>
        Click <DeleteText>Delete</DeleteText> to proceed with deletion or click&nbsp;
        <strong>Cancel</strong> to return to editing the container
      </p>
    </Modal>
  );
};
