import React, { useMemo, useState } from 'react';
import { useController, UseControllerProps, useFormContext } from 'react-hook-form';
import { Select } from 'antd';
import { get, sortBy } from 'lodash';
import { Carrier, getAll } from '@beacon-devops/carrier-provider';
import { Box } from '@beacon-devops/components';
import { SCVContainerFormValues, SearchField } from '@platform/types';
import useDebounce from '@utils/hooks/useDebouncedValue';
import { FormLabel } from '@platform/pages/OceanContainer/styles';
import { Mode } from '@beacon-devops/graphql-typescript-client';

interface CarrierSearchProps {
  label: string;
  disabled?: boolean;
  name: UseControllerProps<SCVContainerFormValues>['name'];
  mode?: Mode;
}

const compare = (text: string, textToCompare: string): boolean =>
  text.toLowerCase().includes(textToCompare.trim().toLowerCase());

const filterWithoutNmftaCode = (carriers: Carrier[]) =>
  carriers.filter((c) => !(c.nmftaCode === undefined || c.nmftaCode === ''));

const filterAirCarriers = (carriers: Carrier[]) => carriers.filter((c) => c.mode === Mode.Air);

const CarrierSearch = ({ label, disabled = false, name, mode = Mode.Ocean }: CarrierSearchProps): JSX.Element => {
  const { control } = useFormContext<SCVContainerFormValues>();
  const [searchValue, setSearchValue] = useState('');
  const {
    field: { value, onChange },
  } = useController({ name, control });

  const carriers = useMemo(() => {
    return mode === Mode.Air ? filterAirCarriers(getAll()) : filterWithoutNmftaCode(getAll());
  }, [mode]);

  const debouncedSearchValue = useDebounce(searchValue, 250);

  const filteredCarriers: SearchField[] = useMemo(() => {
    const transformedCarriers = carriers.reduce((acc: SearchField[], carrier) => {
      const isSearched =
        compare(carrier.nmftaCode || '', debouncedSearchValue) || compare(carrier.displayName, debouncedSearchValue);

      if (isSearched) {
        return [
          ...acc,
          {
            id: carrier.id,
            name: carrier.nmftaCode ? `${carrier.nmftaCode} (${carrier.displayName})` : carrier.displayName,
            mawbPrefix: carrier.mawbPrefix,
          },
        ];
      }
      return acc;
    }, []);

    return sortBy(transformedCarriers, 'label');
  }, [carriers, debouncedSearchValue]);

  const typedValue = value as SearchField;

  return (
    <Box width='100%'>
      <FormLabel>{label}</FormLabel>
      <Select
        style={{ width: '100%' }}
        onSearch={setSearchValue}
        filterOption={false}
        showSearch
        placeholder='Please select a carrier'
        onSelect={(value) => {
          const selectedValue = filteredCarriers.find((carrier) => carrier.id === value);
          if (selectedValue) {
            onChange(selectedValue);
          }
        }}
        value={get(typedValue, 'id', '')}
        disabled={disabled}
      >
        {filteredCarriers?.map(({ name, id }) => (
          <Select.Option value={id} key={id} title={name}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Box>
  );
};

export default CarrierSearch;
