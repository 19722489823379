import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageLayout from '@components/PageLayout';
import UserForm from '@components/User/UserForm';
import { Tabs } from 'antd';
import UserHeader from '@components/User/UserHeader';

const { TabPane } = Tabs;

interface IUserUpdateParams {
  userId: string;
  customerId: string;
}

export const UserUpdate = (): ReactElement => {
  const { userId, customerId } = useParams<IUserUpdateParams>();
  const [activeTab, setActiveTab] = useState('1');

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <PageLayout>
      <div style={{ marginBottom: '32px' }}>
        <UserHeader customerId={customerId} userId={userId} />
      </div>
      <Tabs
        defaultActiveKey='1'
        activeKey={activeTab}
        onChange={handleTabChange}
        style={{ fontSize: '20px', marginTop: '8px' }}
      >
        <TabPane tab='Details' key='1'>
          <UserForm userId={userId} customerId={customerId} />
        </TabPane>
      </Tabs>
    </PageLayout>
  );
};

export default UserUpdate;
