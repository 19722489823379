import React, { useContext, useState } from 'react';
import { isArray } from 'lodash';
import { Alert, Button, Empty, Table, TablePaginationConfig } from 'antd';
import { DeleteOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useHistory, useLocation } from 'react-router';
import { useSearchCargoForAllCustomers } from '@queries/graphql';
import TableHeader from '@components/common/TableHeader';
import { Cargo, Mode, SearchConditionType } from '@beacon-devops/graphql-typescript-client';
import { basePathOcean } from '@platform/routes/paths';
import { useCustomerIdNameMap } from '@platform/hooks/useCustomerIdNameMap';
import { setQueryParam } from '@utils/location';
import { getTableURLParams } from '@utils/table';
import { parseCargoURLParamsFromSearch } from '@utils/platform/cargo';
import { CargoFilterWidgetList } from '@platform/constants/cargoFilterWidgetList';
import { getCargoFilterDefaults } from '@utils/common/filters';
import { useBulkPurgeShipmentData } from '@queries/shipmentTracker/usePurgeShipmentData';
import { getColumns, OceanCargoListColumnKey } from './OceanCargoList.definition';
import { SearchButton } from './styles';
import CargoComplexSearch from '../CargoComplexSearch';
import { CargoSearchContext } from '../CargoSearchProvider/context';
import { ShipmentPurgeModal } from '../ShipmentPurgeModal';

const OceanCargoList = () => {
  const { isOpen: isFiltersOpen, toggleOpen, addFilter, isFilterSelected } = useContext(CargoSearchContext);
  const history = useHistory();
  const location = useLocation();

  const { sort, criteria = [], size, page } = parseCargoURLParamsFromSearch(location.search);
  const {
    data,
    isLoading: isLoadingCargos,
    isError,
    error,
    refetch: refetchSearchedCargos,
  } = useSearchCargoForAllCustomers({
    page,
    size,
    sort: sort || [],
    criteria: [
      {
        field: 'mode',
        condition: SearchConditionType.Equals,
        values: [Mode.Ocean],
      },
      ...criteria,
    ],
  });

  // Cargo purge functionality
  const [selectedRows, setSelectedRows] = useState<Cargo[]>([]);
  const [isPurgeModalOpen, setPurgeModalOpen] = useState(false);
  const { mutate: bulkPurgeShipmentData } = useBulkPurgeShipmentData(
    selectedRows.map((row) => row.carrierShipmentId || ''),
    () => {
      setTimeout(() => {
        setPurgeModalOpen(false);
        refetchSearchedCargos();
        setSelectedRows([]);
      }, 3000);
    },
    () => {
      setPurgeModalOpen(false);
    },
  );

  const customerIds = data?.cargos ? data?.cargos.map((x) => x?.customerId || '') : [];
  const { customerMap, isLoading: isCustomersLoading } = useCustomerIdNameMap(customerIds);

  const filterIconClickHandler = (columnKey: OceanCargoListColumnKey) => {
    if (!isFiltersOpen) {
      const filterWidget = CargoFilterWidgetList.find((widget) => widget.key === OceanCargoListColumnKey[columnKey]);
      toggleOpen();

      if (filterWidget) {
        addFilter({
          ...filterWidget,
          currentValue: getCargoFilterDefaults(filterWidget),
        });
      }
    }
  };

  let tableColumns = getColumns({
    customers: customerMap,
    filterIconClickHandler,
    isFilterSelected,
  });

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Cargo> | SorterResult<Cargo>[],
  ) => {
    // narrowing type here to SorterResult<ContainerDTO> since we're not using multiple sorters at once anyways.
    if (!isArray(sorter)) {
      setQueryParam(
        history,
        getTableURLParams<Cargo>(pagination, sorter) as Record<string, string | undefined | number>,
      );
    }
  };

  const selectedCargoIds = selectedRows.map((row) => row.id);

  return (
    <>
      <TableHeader
        title='Ocean Cargo'
        renderRightSide={() => (
          <>
            {selectedCargoIds.length > 0 && (
              <Button type='primary' danger onClick={() => setPurgeModalOpen(true)} style={{ marginRight: '16px' }}>
                <DeleteOutlined /> Purge
              </Button>
            )}
            <Button onClick={() => refetchSearchedCargos()} style={{ marginRight: '16px' }}>
              <ReloadOutlined /> Refresh
            </Button>
            <SearchButton type='primary' ghost onClick={toggleOpen}>
              <SearchOutlined />
              Search
            </SearchButton>
          </>
        )}
      />
      {isError && (
        <Alert
          message='Error fetching containers'
          showIcon
          description={`Error: ${error?.message} - ${error?.response?.data}`}
          type='error'
          action={
            <Button size='small' danger onClick={() => history.push(basePathOcean)}>
              Reset
            </Button>
          }
        />
      )}
      <div>
        <Table
          rowSelection={{
            onChange: (_, selectedRows) => setSelectedRows(selectedRows),
            selectedRowKeys: selectedCargoIds,
          }}
          pagination={{
            current: page,
            pageSize: size,
            total: data?.totalRecords || 1,
            position: ['bottomCenter'],
          }}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          dataSource={data ? (data.cargos as Cargo[]) : []}
          rowKey='id'
          bordered={false}
          locale={{
            emptyText: <Empty />,
          }}
          columns={tableColumns}
          loading={isLoadingCargos || isCustomersLoading}
        />
      </div>

      <ShipmentPurgeModal
        isOpen={isPurgeModalOpen}
        shipmentNumbers={selectedRows.map((row) => row.container?.containerNumber || '')}
        customer=''
        onCancel={() => setPurgeModalOpen(false)}
        onPurge={() => {
          if (selectedRows.length > 0) {
            bulkPurgeShipmentData();
          }
        }}
      />
      <CargoComplexSearch />
    </>
  );
};

export default OceanCargoList;
