import ArrowLeftOutlined from '@ant-design/icons/lib/icons/ArrowLeftOutlined';
import EyeOutlined from '@ant-design/icons/lib/icons/EyeOutlined';
import { USERS_ROUTE, USER_DETAIL_ROUTE } from '@routes/constants';
import { Typography, Button, Card, Col, Row, Tooltip } from 'antd';
import React, { ReactElement } from 'react';
import { Link, useHistory } from 'react-router-dom';

const { Title } = Typography;

interface IUserHeaderProps {
  customerId?: string;
  userId?: string;
}

const UserHeader = ({ customerId, userId }: IUserHeaderProps): ReactElement => {
  const { goBack } = useHistory();
  const isEditMode = userId && customerId;
  const isInviteToCompanyMode = !userId && customerId;

  return (
    <Card>
      <Row align='middle' justify='space-between'>
        <Col>
          <Row align='middle' justify='start'>
            <Col>
              {isInviteToCompanyMode || isEditMode ? (
                <Button className='ant-btn ant-btn-text' onClick={goBack}>
                  <ArrowLeftOutlined />
                </Button>
              ) : (
                <Link className='ant-btn ant-btn-text' to={USERS_ROUTE}>
                  <ArrowLeftOutlined />
                </Link>
              )}
            </Col>
            <Col>
              <Title level={3} style={{ margin: 0 }}>
                {isEditMode ? 'Update User' : 'Invite User'}
              </Title>
            </Col>
          </Row>
        </Col>
        {isEditMode && (
          <Col>
            <Tooltip placement='bottom' title='View User'>
              <Link
                to={USER_DETAIL_ROUTE.replace(':customerId', customerId as string).replace(':userId', userId as string)}
                className='ant-btn ant-btn-text'
              >
                <EyeOutlined />
              </Link>
            </Tooltip>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default UserHeader;
