import React from 'react';
import { Select } from 'antd';
import { useGetCustomersQuery } from '@queries/customers';
import { Customer } from '@beacon-devops/customer-service-client';

interface FilterOptionsType {
  label: string;
  value: string;
}

const mapCustomerNameToDropDownValues = (customers: Customer[]): FilterOptionsType[] =>
  customers.map((customer: Customer) => ({ label: customer.fullCompanyName, value: customer.customerId }));

interface CustomerLookupWithoutFormProps {
  onSelect: (value: string) => void;
  onDeselect: (value: string) => void;
  value: string[];
}

export const CustomerLookupWithoutForm = ({
  onDeselect,
  onSelect,
  value,
}: CustomerLookupWithoutFormProps): React.ReactElement => {
  const { data: customersData, isLoading } = useGetCustomersQuery({ activeOnly: true, isFreeTracking: false });
  const mappedData = mapCustomerNameToDropDownValues(customersData || []);

  return (
    <Select
      placeholder='Select'
      notFoundContent={null}
      options={mappedData}
      mode='multiple'
      style={{ width: '100%' }}
      loading={isLoading}
      value={value}
      onSelect={onSelect}
      onDeselect={onDeselect}
      filterOption={(input, option) => ((option?.label as string) || '').toLowerCase().includes(input.toLowerCase())}
      showSearch
    />
  );
};
