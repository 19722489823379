import React from 'react';
import { Spin } from 'antd';
import FullPageWrapper from './styles';

const FullPageSpinner = () => (
  <FullPageWrapper>
    <Spin size='large' />
  </FullPageWrapper>
);

export default FullPageSpinner;
