import React, { ReactElement, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUsers } from '@state/users';
import { getAllUsers, getAllUsersLoading } from '@state/users/selectors';
import TableHeader from '@components/common/TableHeader';
import { getColumns } from './UsersList.definition';
import TableView from '../common/TableView';

const UsersList = (): ReactElement => {
  const dispatch = useDispatch();
  const users = useSelector(getAllUsers);
  const isLoading = useSelector(getAllUsersLoading);
  const getData = useCallback(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <TableHeader title='Users' kind='WITH_REFRESH' refreshButtonText='Reload Users' onRefreshClick={getData} />
      <TableView
        data={users}
        pagination={{ position: ['bottomCenter'] }}
        getColumns={getColumns}
        isLoading={isLoading}
      />
    </>
  );
};

export default UsersList;
