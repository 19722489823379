import PageLayout from '@components/PageLayout';
import { Button, Card, Col, Form, Input, Row, Select, Space } from 'antd';
import React from 'react';
import { ScvEntityType } from '@platform/components/ScvCommanderEntity/ScvCommanderEntityUtils';
import ScvLogo from '../../../static/img/scv.png';

export function ScvCommanderPage() {
  // Form options and layout:
  const [form] = Form.useForm();
  const formLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const onFinish = (values) => {
    const entityType = values['entity-type'] ?? ScvEntityType.cargo;
    window.location.replace(`/platform/scvcom/${entityType}/${values['entity-id']}`);
  };

  return (
    <PageLayout>
      <div style={{ textAlign: 'left', padding: '32px' }}>
        <h2>
          <img src={ScvLogo} height={30} alt='' /> SCV Commander
        </h2>
        <Row>
          <Col span={24}>
            <Card title='Welcome'>
              <p>Interact directly with the Shipment Tracker API!</p>
              <p>
                Any entity can be inspected with a URL that looks like this: http://(host)/platform/scvcom/
                <strong>(entity type)</strong>/<strong>(entity ID)</strong>
              </p>
              <p>Alternatively, use the form below to get started:</p>

              <Col span={12}>
                <Form form={form} name='entity-select-form' {...formLayout} onFinish={onFinish}>
                  <Form.Item name='entity-id' label='Entity ID'>
                    <Input />
                  </Form.Item>
                  <Form.Item name='entity-type' label='Type'>
                    <Select
                      defaultValue={ScvEntityType.cargo}
                      options={[
                        {
                          value: ScvEntityType.cargo,
                          label: 'Cargo',
                        },
                        {
                          value: ScvEntityType.carrierShipment,
                          label: 'Carrier Shipment',
                        },
                        {
                          value: ScvEntityType.location,
                          label: 'Location',
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type='primary' htmlType='submit'>
                      Go!
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: 16 }}>
          <Col span={24}>
            <Card title='Introduction'>
              <iframe
                title='Demo Video'
                width={480}
                height={270}
                src='https://www.loom.com/embed/9fc94bbc3f674f87b257c411f97a5f52?sid=e961cb4a-6bba-4c55-a5fa-c14ae7d70ec0'
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
              />
            </Card>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
}
