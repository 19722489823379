import React, { FunctionComponent } from 'react';
import { Switch, Typography, Layout } from 'antd';
import { useDispatch } from 'react-redux';
import { sessionStorageService } from '@utils/generalUtilities';
import './style.less';

const { Text } = Typography;
const { Content } = Layout;

const TestMode: FunctionComponent = () => {
  const dispatch = useDispatch();

  const onChange = (isTest: boolean) => {
    sessionStorageService.setItem('isTest', isTest);
  };

  return (
    <Content className='bo__test-mode-container'>
      <Text>Show Test Data</Text>
      <Switch className='test-switch' onChange={onChange} defaultChecked={!!sessionStorageService.getItem('isTest')} />
    </Content>
  );
};

export default TestMode;
