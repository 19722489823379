import React from 'react';
import { DatePicker, DatePickerProps } from 'antd';
import moment from 'moment';
import { useController, UseControllerProps, useFormContext } from 'react-hook-form';
import { FormLabel } from '@platform/pages/OceanContainer/styles';
import { SCVContainerFormValues } from '@platform/types';
import { ValidationRange } from '@platform/utils/handleValidation';
import { Box } from '@beacon-devops/components';
import { EventDateTime } from '@beacon-devops/container-tracking-api-model-ts-client';
import { noop } from 'lodash';
import { getDefaultTime, ISO_WITHOUT_TIMEZONE } from './utils';

interface ScvContainerDateProps {
  label: string;
  name: UseControllerProps<SCVContainerFormValues>['name'];
  validationRange?: ValidationRange;
  renderOnUpdate?: boolean;
  defaultTimeNow?: boolean;
  onDelete?: () => void;
  onUndelete?: () => void;
}

// const isDisabledDate = (current: moment.Moment, validationRange?: ValidationRange) => {
//   if (!validationRange) {
//     return false;
//   }
//   const { from, to } = validationRange;
//   if (from != null && current.isSame(from, 'day')) {
//     return false;
//   }
//
//   if (from != null && current.isBefore(from)) {
//     return true;
//   }
//
//   if (to != null && current.isSame(to, 'day')) {
//     return false;
//   }
//
//   if (to != null && current.isAfter(to)) {
//     return true;
//   }
//   return false;
// };

export const ScvContainerDate = ({
  label,
  name,
  renderOnUpdate = false,
  defaultTimeNow = false,
  validationRange,
  onDelete = noop,
  onUndelete = noop,
  ...rest
}: ScvContainerDateProps & DatePickerProps) => {
  const { control, watch } = useFormContext<SCVContainerFormValues>();
  const formController = useController({ name, control });
  const {
    field: { onChange },
  } = formController;

  // decision to use `formContext.watch` here because `field.value` doesn't update when form values are updated outside
  // the field component. The alternative is to reset the form when the data is changed from outside the form
  const typedValue = (renderOnUpdate ? watch(name) : formController.field.value) as EventDateTime;

  const inputValue = typedValue?.timestamp ? moment(typedValue.timestamp) : undefined;
  const defaultTimeValue = getDefaultTime(typedValue?.zone, defaultTimeNow);

  const onChangeDate = (value: moment.Moment | null) => {
    const updatedValueEventDatetime = {
      ...typedValue,
      date: '',
      timestamp: '',
    };

    if (value) {
      updatedValueEventDatetime.timestamp = value?.format(ISO_WITHOUT_TIMEZONE);
    }

    onChange(updatedValueEventDatetime);

    if (!value) {
      onDelete();
    } else {
      onUndelete();
    }
  };

  return (
    <Box width='200px'>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <DatePicker
        {...rest}
        id={name}
        picker='date'
        style={{ width: '100%' }}
        value={inputValue}
        onChange={onChangeDate}
        showTime={{ defaultValue: defaultTimeValue }}
        // disabledDate={(current: moment.Moment) => isDisabledDate(current, validationRange)}
      />
    </Box>
  );
};
