import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { PurgeText } from './styles';

interface Props {
  isOpen: boolean;
  shipmentNumbers: string[];
  customer: string;
  onPurge: () => void;
  onCancel: () => void;
}

export const ShipmentPurgeModal = ({ isOpen, shipmentNumbers, customer, onCancel, onPurge }: Props) => {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setLoading(false);
    }
  }, [isOpen]);

  return (
    <Modal
      title='Purging shipment(s)'
      okButtonProps={{
        danger: true,
      }}
      onCancel={onCancel}
      footer={[
        <Button key='back' onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          data-testid='container-modal-purge-button'
          key='submit'
          type='primary'
          danger
          onClick={() => {
            // on refetch later we have a huge setTimeout, so we can't use the real isLoading prop from purgeShipment mutation
            // thus why we use this local state to imitate "real" loading experience
            setLoading(true);
            onPurge();
          }}
          loading={isLoading}
          disabled={isLoading}
        >
          Purge
        </Button>,
      ]}
      visible={isOpen}
    >
      <p>
        You are purging the shipment(s) of <strong>{shipmentNumbers.join(', ')}</strong> for <strong>{customer}</strong>
        .
      </p>
      <p>
        This will reset all statuses and fields on the shipment(s) and the cargo(s). For air shipments, it will also
        delete ALL cargos for the shipment(s) except the main parent cargo.
      </p>
      <p>
        A stop tracking signal will be sent to immediately stop tracking this shipment. If you want to re-enable
        tracking you will need to re-ingest the container / MAWB.
      </p>
      <p>
        Click <PurgeText>Purge</PurgeText> to proceed with purge or click&nbsp;
        <strong>Cancel</strong> to return to the cargo.
      </p>
    </Modal>
  );
};
