import TableHeader from '@components/common/TableHeader';
import { Alert, Button, Empty, message, Table, TablePaginationConfig } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { isArray } from 'lodash';
import { setQueryParam } from '@utils/location';
import { getTableURLParams } from '@utils/table';
import { ReportDto } from '@beacon-devops/shipment-data-quality-engine-ts-client';
import { parseCargoURLParamsFromSearch } from '@utils/platform/cargo';
import { getColumns } from '@platform/components/FlaggedShipmentList/FlaggedShipmentList.definition';
import ReleaseConfirmDialog from '@platform/components/FlaggedShipmentList/releaseConfirmDialog';
import { useSearchFlaggedShipments } from '@queries/flaggedShipments';
import { basePathFlaggedShipments } from '@platform/routes/paths';
import { RuleResultsDialog } from './rulesResultDialog';
import { DeleteConfirmDialog } from './deleteConfirmDialog';

export interface SetReportArgs {
  reportId: string;
  fieldName: 'rulesResult' | 'dataItem';
}

export const FlaggedShipmentList = () => {
  const history = useHistory();
  const location = useLocation();

  const { size, page } = parseCargoURLParamsFromSearch(location.search);

  const {
    data,
    isLoading: isFlaggedShipmentsLoading,
    isError,
    error,
    refetch: refetchFlaggedShipments,
  } = useSearchFlaggedShipments({ page, size });

  const [selectedReleasePrompt, setSelectedReleasePrompt] = React.useState<string | null>(null);
  const [selectedDeletePrompt, setSelectedDeletePrompt] = React.useState<string | null>(null);

  const [selectedReportDetails, setReportDetails] = React.useState<SetReportArgs | null>(null);

  const [messageApi, contextHolder] = message.useMessage();

  const triggerReleasePrompt = (reportId: string) => {
    setSelectedReleasePrompt(reportId);
  };
  const triggerDeletePrompt = (reportId: string) => {
    setSelectedDeletePrompt(reportId);
  };

  const closeReleasePrompt = () => {
    setSelectedReleasePrompt(null);
  };
  const closeDeletePrompt = () => {
    setSelectedDeletePrompt(null);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ReportDto> | SorterResult<ReportDto>[],
  ) => {
    if (!isArray(sorter)) {
      setQueryParam(
        history,
        getTableURLParams<ReportDto>(pagination, sorter) as Record<string, string | undefined | number>,
      );
    }
  };

  let tableColumns = getColumns({ triggerReleasePrompt, triggerDeletePrompt, setReportDetails });

  return (
    <>
      {contextHolder}
      <TableHeader
        title='Flagged Shipments'
        renderRightSide={() => (
          <>
            <Button onClick={() => refetchFlaggedShipments()} style={{ marginRight: '16px' }}>
              <ReloadOutlined /> Refresh
            </Button>
          </>
        )}
      />

      {isError && (
        <Alert
          message='Error fetching flagged containers'
          showIcon
          description={`Error: ${error?.message} - ${error?.response?.data}`}
          type='error'
          action={
            <Button size='small' danger onClick={() => history.push(basePathFlaggedShipments)}>
              Reset
            </Button>
          }
        />
      )}

      <div>
        <Table
          pagination={{
            current: data?.page,
            pageSize: data?.pageSize,
            total: data?.totalRecords || 0,
            position: ['bottomCenter'],
          }}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          dataSource={data?.reports as Array<ReportDto>}
          rowKey='dataId'
          bordered={false}
          locale={{
            emptyText: <Empty />,
          }}
          columns={tableColumns}
          loading={isFlaggedShipmentsLoading}
        />
      </div>

      {selectedReleasePrompt && (
        <ReleaseConfirmDialog messageApi={messageApi} reportId={selectedReleasePrompt} onClose={closeReleasePrompt} />
      )}
      {selectedDeletePrompt && (
        <DeleteConfirmDialog messageApi={messageApi} reportId={selectedDeletePrompt} onClose={closeDeletePrompt} />
      )}
      {selectedReportDetails && (
        <RuleResultsDialog
          messageApi={messageApi}
          onClose={() => setReportDetails(null)}
          reportId={selectedReportDetails.reportId}
          fieldName={selectedReportDetails.fieldName}
        />
      )}
    </>
  );
};
