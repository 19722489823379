import React, { ReactElement, useEffect, useState } from 'react';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _remove from 'lodash/remove';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _omit from 'lodash/omit';
import _entries from 'lodash/entries';
import _toLower from 'lodash/toLower';
import { Card, Form, Row, Spin, Input, Select, Button, message, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { Customer, Role, UserGroup } from '@beacon-devops/customer-service-client';
import { emailRule, requiredRule } from '@utils/forms/rules';
import { notEmpty } from '@utils/generalUtilities';
import { User } from '@services/constants';
import UserService from '@services/User';
import CustomerService from '@services/CustomerService';
import { USER_DETAIL_ROUTE } from '@routes/constants';
import UsersRolesTable from '@pages/SingleCustomer/UsersRolesTable';
import { AxiosError } from 'axios';
import { SetPermission, UserWithRoles } from '@beacon-types/customer';
import './style.less';

const { Option } = Select;

interface UserDetailProps {
  userId?: string;
  customerId?: string;
}

const UserForm = ({ userId, customerId }: UserDetailProps): ReactElement => {
  const isEditMode = notEmpty(userId) && notEmpty(customerId);
  const isInviteToCompanyMode = _isEmpty(userId) && notEmpty(customerId);
  const history = useHistory();
  const [user, setUser] = useState<UserWithRoles>({} as UserWithRoles);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [customers, setCustomers] = useState([] as Customer[]);
  const [form] = Form.useForm();
  const [internalRoles, setInternalRoles] = useState([] as Role[]);
  const [externalRoles, setExternalRoles] = useState([] as Role[]);
  // const [currentRole, setCurrentRole] = useState(UserGroup.External);
  const [rolesList, setRolesList] = useState([] as Role[]);
  const userRoles = _get(user, 'permissions', []);
  const [, forceUpdate] = useState({});
  const [companyUsers, setCompanyUsers] = useState<User[]>([]);
  const [primaryUserEmail, setPrimaryUserEmail] = useState<string>('');
  const [userGroupWarningMessage, setUserGroupWarningMessage] = useState('');
  const groups = [
    { label: 'Internal', value: 'Internal' },
    { label: 'External', value: 'External' },
  ];

  const initializeFormData = async () => {
    setIsLoading(true);
    let availableExternalRoles: Role[];
    if (isEditMode) {
      const user = userId ? await UserService.getUserById(userId) : await Promise.resolve({} as User);
      const roles =
        customerId && userId ? await UserService.getUserRoles(customerId, userId) : await Promise.resolve([] as Role[]);

      availableExternalRoles = await UserService.getRolesByCustomer(customerId || user.companyId);

      setUser({ ...user, permissions: roles });
    } else {
      availableExternalRoles = [];
    }

    const customers = await CustomerService.getAll(undefined, false);
    setCustomers(customers);

    const availableInternalRoles = await UserService.getInternalRolesList();

    setExternalRoles(availableExternalRoles);
    setInternalRoles(availableInternalRoles);

    setRolesList(user.userGroup === UserGroup.Internal ? availableInternalRoles : availableExternalRoles);
    setIsLoading(false);

    // To disable submit button at the beginning.
    forceUpdate({});
  };

  const setUserPermissionDetails = ({ role, selected }: SetPermission) => {
    const clonedUser = { ...(user || {}) };
    const internalFreightRoleRemoved = role.name === 'Back Office Freight' && !selected;
    const internalFreightRoleAdded = role.name === 'Back Office Freight' && selected;
    if (selected) {
      _set(clonedUser, `permissions`, [...userRoles, role]);
    } else {
      const updatedPermissions = _remove(userRoles, ({ id }) => role.id !== id);
      _set(clonedUser, `permissions`, updatedPermissions);
    }

    // To remove this case once internal roles are managed by google groups
    if (internalFreightRoleRemoved) {
      setUserGroupWarningMessage('This user will loose access to the backoffice');
    } else if (internalFreightRoleAdded) {
      setUserGroupWarningMessage('');
    }
    setUser(clonedUser);
  };

  const updateUserValues = async (formValues: UserWithRoles) => {
    const clonedUser = { ...(user || {}) };
    setUser({ ...clonedUser, ...formValues });
  };
  const setCustomerPrimayOwnerEmail = async () => {
    if (customerId) {
      const customer = await CustomerService.getCustomer(customerId);
      setPrimaryUserEmail(_get(customer, 'ownerDetails.email'));
    } else {
      setPrimaryUserEmail('');
    }
  };

  const setDefaultRolesForPrimaryUser = async () => {
    if (notEmpty(user.companyId) && user.userGroup === UserGroup.External) {
      const users = await UserService.getAllUsersByCustomerId(user.companyId);
      const accessAdminRoleDetails = _find(rolesList, { name: 'Access Admin' });
      const hasAdminRole = _find(userRoles, { name: 'Access Admin' });

      if (_isEmpty(users) && accessAdminRoleDetails && !hasAdminRole) {
        setUser({ ...user, permissions: [...userRoles, accessAdminRoleDetails] });
      }
      setCompanyUsers(_filter(users, { userGroup: UserGroup.External }));
    }
  };
  const tableProps = {
    users: notEmpty(user) ? [user] : ([{}] as UserWithRoles[]),
    setUserPermissionDetails,
    rolesList,
    permissionDataIndex: 'permissions',
    showUserEmail: false,
    totalItems: notEmpty(user) ? 1 : 0,
    editMode: isEditMode,
    inviteToCompanyMode: isInviteToCompanyMode,
    customerId: customerId || user.companyId,
    isPrimaryUser: _isEmpty(companyUsers),
    primaryUserEmail,
  };

  useEffect(() => {
    initializeFormData();

    return () => {
      setUser({} as UserWithRoles);
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFields(
      _entries(user).map(([name, value]) => ({
        name,
        value,
      })),
    );
  }, [form, user, companyUsers, isEditMode]);

  useEffect(() => {
    if (isEditMode) {
      setCustomerPrimayOwnerEmail();
    } else {
      setDefaultRolesForPrimaryUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.companyId, internalRoles, externalRoles]);

  const initialiseExternalRolesForCustomerId = async () => {
    const externalRoles = await UserService.getRolesByCustomer(customerId || user.companyId);
    setRolesList(user.userGroup === UserGroup.Internal ? internalRoles : externalRoles);
  };

  useEffect(() => {
    if (user.companyId || customerId) {
      initialiseExternalRolesForCustomerId();
    }
  }, [user.userGroup]);

  const isValid = () => {
    const fieldErrors = form.getFieldsError().filter(({ errors }) => notEmpty(errors));
    return _isEmpty(fieldErrors) && (user.userGroup === UserGroup.Internal || notEmpty(userRoles));
  };

  const createOrUpdateUser = async () => {
    setIsFormSubmitting(true);

    if (isEditMode && customerId && userId) {
      try {
        const userVersion = _get(user, 'version', 'v0');
        const newRoles = userRoles.filter((role) => role.userGroup === user.userGroup).map((role) => role.id);
        const userDetails = {
          ..._omit(user, ['permissions']),
          companyId: customerId,
        };
        await UserService.updateUser(userId, userVersion, userDetails, newRoles);
        const updatedUser = await UserService.getUserById(userId);
        const updatedRoles = await UserService.getUserRoles(customerId, userId);
        setUser({ ...updatedUser, permissions: updatedRoles });
        message.success(`Successfully updated user`);
      } catch (e) {
        console.error(e);
        message.error(`Could not update user - ${(e as AxiosError).response?.data}`);
      }
    } else if (isInviteToCompanyMode || user.companyId) {
      try {
        if (isInviteToCompanyMode && customerId) {
          // if we come from company details page, we prefill the customerId from the URL and dont put it in the form. Same with UserGroup
          user.companyId = customerId;
        }
        const userDetails = { ..._omit(user, ['permissions']) };

        const newRoles = userRoles.filter((role) => role.userGroup === userDetails.userGroup).map((role) => role.id);
        const newUser = await UserService.addUser(userDetails, newRoles);
        const updatedRoles = await UserService.getUserRoles(newUser.companyId, newUser.id);

        setUser({ ...newUser, permissions: updatedRoles });
        message.success(`Successfully created user`);

        if (isInviteToCompanyMode) {
          history.goBack();
        } else {
          history.push(USER_DETAIL_ROUTE.replace(':customerId', newUser.companyId).replace(':userId', newUser.id));
        }
      } catch (e) {
        message.error(`Error - ${(e as AxiosError).response?.data?.error}`);
      }
    }
    setIsFormSubmitting(false);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const optionallyValidateUserGroup = (changedField: any) => {
    const [fieldName, fieldValue] = _entries(changedField)[0];
    const changedToInternalUserGroup =
      fieldName === 'userGroup' && fieldValue === UserGroup.Internal && user.userGroup === UserGroup.External;
    const changedToExternalUserGroup =
      fieldName === 'userGroup' && fieldValue === UserGroup.External && user.userGroup === UserGroup.Internal;

    if (changedToInternalUserGroup) {
      setUserGroupWarningMessage('If you change the User Group to Internal, all External permissions will be removed');
    } else if (changedToExternalUserGroup) {
      setUserGroupWarningMessage('If you change the User Group to External, internal permissions will be removed');
    } else {
      setUserGroupWarningMessage('');
    }
  };

  return isLoading ? (
    <Row justify='center'>
      <Spin size='large' />
    </Row>
  ) : (
    <>
      <Card>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 12 }}
          layout='horizontal'
          initialValues={user}
          onFinish={createOrUpdateUser}
          form={form}
          onValuesChange={(changedField, formValues) => {
            if (isEditMode) optionallyValidateUserGroup(changedField);
            updateUserValues(formValues);
          }}
        >
          <Form.Item label='Email Address' name='email' rules={[requiredRule, emailRule]}>
            <Input disabled={isEditMode} />
          </Form.Item>
          {!isEditMode && !isInviteToCompanyMode && (
            <Form.Item label='Customer' name='companyId' rules={[requiredRule]}>
              <Select
                showSearch
                filterOption={(inputValue, option) => {
                  const fullCompanyName = _get(option, 'children', null);
                  return fullCompanyName && inputValue
                    ? _toLower(fullCompanyName).includes(_toLower(String(inputValue)))
                    : false;
                }}
              >
                {customers.map((customer) => (
                  <Option key={customer.customerId} value={customer.customerId}>
                    {customer.fullCompanyName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {isInviteToCompanyMode && (
            <Form.Item label='Customer' name='companyId' required>
              <Input disabled defaultValue={customerId} value={customerId} />
            </Form.Item>
          )}
          <Form.Item label='First Name' name='firstName'>
            <Input />
          </Form.Item>
          <Form.Item label='Last Name' name='lastName'>
            <Input />
          </Form.Item>

          {(user.companyId || customerId) && (
            <Form.Item label='User Group' name='userGroup' rules={[requiredRule]}>
              <Select>
                {groups.map((group) => (
                  <Option key={group.value} value={group.value}>
                    {group.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {(user.companyId || customerId) && notEmpty(user.userGroup) && (
            <>
              {/* Once google group sync is in place then put back the alret label. */}
              {user.userGroup === UserGroup.Internal && (
                <Form.Item label={<span />} colon={false}>
                  <Alert
                    message='Internal roles are managed in Google groups by the manager of the team.'
                    type='info'
                  />
                </Form.Item>
              )}
              {notEmpty(userGroupWarningMessage) && (
                <Form.Item label={<span />} colon={false}>
                  <Alert message={userGroupWarningMessage} type='warning' />
                </Form.Item>
              )}

              <Form.Item label='Roles' required>
                <div className='user-invite-table'>
                  <UsersRolesTable {...tableProps} />
                </div>
              </Form.Item>
            </>
          )}
          <Form.Item wrapperCol={{ offset: 4 }} shouldUpdate>
            {() => (
              <Button
                loading={isFormSubmitting}
                disabled={!isValid() || isFormSubmitting}
                type='primary'
                htmlType='submit'
              >
                Submit
              </Button>
            )}
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default UserForm;
