import React, { FC } from 'react';
import { CDetailsSelect, FormLabel } from '@platform/pages/OceanContainer/styles';
import { Controller, useFormContext } from 'react-hook-form';
import { EquipmentKind } from '@beacon-devops/container-tracking-api-model-ts-client';
import { Cargo } from '@beacon-devops/graphql-typescript-client';
import { SCVContainerFormValues } from '@platform/types';

type Props = {
  cargo?: Cargo;
};

const EquipmentKindInput: FC<Props> = ({ cargo }) => {
  const { control } = useFormContext<SCVContainerFormValues>();

  return (
    <>
      <FormLabel htmlFor='equipmentKind'>Equipment Kind</FormLabel>
      <Controller
        control={control}
        name='equipmentKind'
        render={({ field: { onChange, value } }) => (
          <CDetailsSelect
            value={value}
            showSearch
            onChange={(value) => {
              if (cargo) onChange(value);
            }}
            options={Object.values(EquipmentKind)
              .sort()
              .map((ek) => ({
                value: ek,
                label: ek.replace('EQUIPMENT_KIND', '').replace(/_/g, ' '),
              }))}
          />
        )}
      />
    </>
  );
};

export default EquipmentKindInput;
