import { CreateOrUpdateCustomerProps, CustomerFormProps } from '@beacon-types/customer';
import { Form, Typography, Alert, Space, Row, Col } from 'antd';
import React, { ReactElement } from 'react';
import UsersRolesTable from './UsersRolesTable';

const { Title, Text } = Typography;

const EditUserForm = ({
  users = [],
  removeUser,
  setUserPermissionDetails,
  setUserEmail,
  isValid,
  rolesList = [],
  fullCompanyName,
  isTest,
  editMode,
  customerId,
}: CustomerFormProps & CreateOrUpdateCustomerProps): ReactElement => {
  const tableProps = {
    users,
    setUserPermissionDetails,
    setUserEmail,
    removeUser,
    rolesList,
    permissionDataIndex: 'permissions',
    showUserEmail: true,
    totalItems: users.length,
    editMode: !!editMode,
    customerId,
    disableDelete: true,
  };

  return (
    <Form className='create-customer-form__invite_user' wrapperCol={{ span: 18 }} layout='horizontal'>
      <Row>
        <Col span={20}>
          <Space direction='vertical' size={16}>
            <Title level={5}>User &amp; Permissions</Title>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <Space direction='vertical' size={33}>
            <Text className='create-customer-form__description'>
              Edit email adress. Add or remove permissions to the user. New email adress will receive an email
              invitation.
            </Text>

            {!isValid({ users, fullCompanyName, isTest }) && (
              <Alert
                message=''
                description='No permissions selected for user. Please add at least one permission.'
                type='error'
              />
            )}
            <div className='user-invite-table'>
              <UsersRolesTable {...tableProps} />
            </div>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default EditUserForm;
