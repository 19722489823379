import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { Location, TransportCall } from '@beacon-devops/shipment-tracker-client';
// Local imports
import { getLocationById } from '@services/ShipmentTrackerService/ShipmentTrackerService';
import { TitleBar, TitleContainer, TitleWithoutMargin } from './styles';
import {
  AddInspectionEntity,
  renderEntityItem,
  renderEntityItemDateTime,
  buildLocationLink,
} from './ScvCommanderEntityUtils';

interface ScvCommanderEntityTransportCallProps {
  entityId: string;
  entityData: TransportCall;
  addInspectionEntityFunc: AddInspectionEntity;
  backgroundColor: string;
}

/**
 * This component renders a Transport Call given some data from Shipment Tracker.
 */
export const ScvCommanderEntityTransportCall = (props: ScvCommanderEntityTransportCallProps): JSX.Element => {
  const transportCall: TransportCall = props.entityData;
  const [location, setLocation] = useState<Location>();

  useEffect(() => {
    if (transportCall.locationId) {
      getLocationById(transportCall.locationId)
        .then((data) => {
          setLocation(data);
        })
        .catch((error) => {
          console.error('Error fetching location:', error);
        });
    }
  }, [transportCall.locationId]);

  /**
   * Renders the transport call card plus any extra information for this entity
   * @param transportCall TransportCall entity from Shipment Tracker
   */
  function render(transportCall: TransportCall): JSX.Element {
    return (
      <>
        <TitleBar>
          <TitleContainer>
            <TitleWithoutMargin level={5}>
              Transport Call {transportCall?.locationId ? `: ${transportCall.locationId}` : ''}
            </TitleWithoutMargin>
          </TitleContainer>
        </TitleBar>
        <Card>
          {renderEntityItemDateTime('Created At', transportCall.createdAt)}
          {renderEntityItemDateTime('Last Updated At', transportCall.updatedAt)}
          {renderEntityItem('Mode', transportCall.mode)}
          {renderEntityItem(
            'Location',
            buildLocationLink(location, transportCall.locationId, props.addInspectionEntityFunc),
          )}
          {renderEntityItem('Vehicle', transportCall.vehicleId)}
          {renderEntityItem('Inbound Journey', transportCall.inboundJourneyId)}
          {renderEntityItem('Outbound Journey', transportCall.outboundJourneyId)}
        </Card>
      </>
    );
  }

  return <div>{transportCall ? render(transportCall) : '...loading'}</div>;
};
