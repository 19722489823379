import { Form, Typography, Alert, Button, Space, Row, Col } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import React, { ReactElement } from 'react';
import { CustomerFormProps } from '@beacon-types/customer';
import UsersRolesTable from './UsersRolesTable';

const { Title, Text } = Typography;

const UserInviteForm = ({
  users = [],
  removeUser,
  setUserPermissionDetails,
  setUserEmail,
  addUser,
  canGoNext,
  rolesList = [],
  fullCompanyName,
  isTest,
  editMode,
  customerId,
}: CustomerFormProps): ReactElement => {
  const tableProps = {
    users,
    setUserPermissionDetails,
    setUserEmail,
    removeUser,
    rolesList,
    permissionDataIndex: 'permissions',
    showUserEmail: true,
    totalItems: users.length,
    editMode: !!editMode,
    customerId,
  };

  return (
    <Form className='create-customer-form__invite_user' wrapperCol={{ span: 18 }} layout='horizontal'>
      <Row>
        <Col span={20}>
          <Space direction='vertical' size={16}>
            <Title level={5}>User &amp; Permissions</Title>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <Space direction='vertical' size={33}>
            <Text className='create-customer-form__description'>
              Enter multiple email addresses to add people to your company. Choose roles to apply to all users.
              <br /> New users will receive an email invitation.
            </Text>

            {users && users.length > 1 && !canGoNext({ users, fullCompanyName, isTest }) ? (
              <Alert
                message=''
                description='No permissions selected for one or more users. Please add at least one permission.'
                type='error'
              />
            ) : (
              <Alert
                message=''
                description='At least one user should be added with Access Admin permission'
                type='info'
              />
            )}
            <div className='user-invite-table'>
              <UsersRolesTable {...tableProps} />
            </div>
            <Button className='add-user-button' onClick={() => addUser()} type='link'>
              <Space size={5}>
                <PlusCircleFilled style={{ fontSize: '16px' }} />
                Add other users
              </Space>
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default UserInviteForm;
