import React, { ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import Title from 'antd/lib/typography/Title';
import { ReloadOutlined, PlusOutlined, ExportOutlined, UserSwitchOutlined, EditOutlined } from '@ant-design/icons';

type TableHeaderProps = {
  kind?: 'STANDARD' | 'BOOKING' | 'WITH_REFRESH' | 'EXPORTS';
  title: string;
  renderRightSide?: () => ReactElement;
  refreshButtonText?: string;
  onRefreshClick?: () => void;
  createButtonPath?: string;
  createButtonText?: string;
  exportButtonText?: string;
  onExportClick?: () => void;
  onAssignToPartnerClick?: () => void;
  isAssignToPartnerDisabled?: boolean;
  onEditClick?: () => void;
  isEditDisabled?: boolean;
  onNewClick?: () => void;
};

const TableHeader = ({
  kind,
  title,
  renderRightSide,
  refreshButtonText,
  onRefreshClick,
  createButtonPath,
  createButtonText,
  exportButtonText,
  onExportClick,
  onAssignToPartnerClick,
  isAssignToPartnerDisabled,
  onEditClick,
  isEditDisabled,
  onNewClick,
}: TableHeaderProps): ReactElement => {
  const renderStandardHeader = useMemo(
    () => (
      <Row justify='space-between' wrap={false}>
        <Col>
          <Title level={2}>{title}</Title>
        </Col>
        {renderRightSide && <Col style={{ marginTop: '8px' }}>{renderRightSide()}</Col>}
      </Row>
    ),
    [title, renderRightSide],
  );

  const renderBookingHeader = useMemo(
    () => (
      <Row justify='space-between' wrap={false}>
        <Col>
          <Title level={2}>{title}</Title>
        </Col>
        <Col style={{ marginTop: '8px' }}>
          <Button onClick={onRefreshClick} style={{ marginRight: '16px' }}>
            <ReloadOutlined /> Refresh
          </Button>

          <Button onClick={onAssignToPartnerClick} disabled={isAssignToPartnerDisabled} style={{ marginRight: '16px' }}>
            <UserSwitchOutlined /> Assign To Partner
          </Button>

          <Button onClick={onEditClick} disabled={isEditDisabled} style={{ marginRight: '16px' }}>
            <EditOutlined /> Edit
          </Button>

          <Button type='primary' onClick={onNewClick}>
            <PlusOutlined /> New
          </Button>
        </Col>
      </Row>
    ),
    [title, onRefreshClick, onAssignToPartnerClick, isAssignToPartnerDisabled, onEditClick, isEditDisabled, onNewClick],
  );

  const renderWithRefresh = useMemo(
    () => (
      <Row justify='space-between' wrap={false}>
        <Col>
          <Title level={2}>{title}</Title>
        </Col>
        <Col style={{ display: 'flex', marginTop: '8px' }}>
          {renderRightSide && renderRightSide()}
          <Button onClick={onRefreshClick}>
            <ReloadOutlined /> {refreshButtonText || 'Refresh'}
          </Button>
        </Col>
      </Row>
    ),
    [title, refreshButtonText, renderRightSide, onRefreshClick],
  );

  const renderWithExports = useMemo(
    () => (
      <Row justify='space-between' wrap={false}>
        <Col>
          <Title level={2}>{title}</Title>
        </Col>
        <Col style={{ marginTop: '8px' }}>
          {createButtonPath && (
            <Link to={createButtonPath || '/'}>
              <Button type='primary' style={{ marginRight: '8px' }}>
                <PlusOutlined /> {createButtonText}
              </Button>
            </Link>
          )}
          {onExportClick && (
            <Button style={{ marginRight: '8px' }} onClick={onExportClick}>
              <ExportOutlined /> {exportButtonText}
            </Button>
          )}
          {onRefreshClick && (
            <Button onClick={onRefreshClick}>
              <ReloadOutlined /> {refreshButtonText || 'Refresh'}
            </Button>
          )}
        </Col>
      </Row>
    ),
    [title, createButtonPath, createButtonText, exportButtonText, onExportClick, refreshButtonText, onRefreshClick],
  );

  switch (kind) {
    case 'BOOKING':
      return renderBookingHeader;
    case 'WITH_REFRESH':
      return renderWithRefresh;
    case 'EXPORTS':
      return renderWithExports;
    case 'STANDARD':
    default:
      return renderStandardHeader;
  }
};

export default TableHeader;
