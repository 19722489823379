import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _set from 'lodash/set';
import _some from 'lodash/some';
import _isEmpty from 'lodash/isEmpty';
import _findIndex from 'lodash/findIndex';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import { Customer, CustomerCapability, Role } from '@beacon-devops/customer-service-client';
import _keys from 'lodash/keys';
import _remove from 'lodash/remove';
import { Button, Col, message, Modal, Row, Space } from 'antd';
import { getEnabledCustomerPlan } from '@utils/customer/customerCapabilities';
import { notEmpty } from '@utils/generalUtilities';
import Title from 'antd/lib/typography/Title';
import UserService from '@services/User';
import './styles.less';
import { CUSTOMERS_ROUTE } from '@routes/constants';
import {
  AddCredits,
  CustomerOnboardingFormProps,
  SetPermission,
  SetUserEmailProps,
  UserWithRoles,
} from '@beacon-types/customer';
import { User } from '@services/constants';
import getEditableCapabilitiesFromSavedCapabilities from '@utils/customer/getEditableCapabilitiesFromSavedCapabilities';
import createCustomerWorkflow, { COMPANY_FORM_ROUTE, COMPANY_PREVIEW_ROUTE } from './customerOnboarding.definition';
import { BackButton } from './BackButton';

const { success } = Modal;

const CustomerOnboardingForm: FC<CustomerOnboardingFormProps> = ({
  customer,
  customerId,
  editMode,
  setCustomer: updateCustomer,
  capabilityDefinitions,
}) => {
  const history = useHistory();
  const templateUser = {
    id: _uniqueId(),
  } as UserWithRoles;
  const initialStep = _get(createCustomerWorkflow, editMode ? COMPANY_PREVIEW_ROUTE : COMPANY_FORM_ROUTE);
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [fullCompanyName, setFullCompanyName] = useState(customer?.fullCompanyName || '');
  const [legalBusinessEntityName, setLegalBusinessEntityName] = useState(customer?.legalBusinessEntity?.name || '');
  const [legalBusinessEntityRegId, setLegalBusinessEntityRegId] = useState(
    customer?.legalBusinessEntity?.registrationId || '',
  );

  const [usersLoaded, setUsersLoaded] = useState(false);
  const [customerLoaded] = useState(notEmpty(customer));
  const [companyDomainsCombined, setCompanyDomainsCombined] = useState<string[]>(customer?.companyDomains || []);
  const [isTest, setIsTest] = useState(customer?.isTest || false);
  const [users, setUsers] = useState<UserWithRoles[]>(customer ? [] : [templateUser]);
  const [rolesList, setRolesList] = useState([] as Role[]);

  const editableCapabilities = getEditableCapabilitiesFromSavedCapabilities(
    capabilityDefinitions || [],
    customer?.capabilities || [],
  );
  const enabledCustomerPlan = getEnabledCustomerPlan(capabilityDefinitions || [], customer?.capabilities || []);

  const [capabilities, setCapabilities] = useState<CustomerCapability[] | undefined>(editableCapabilities);
  const [enabledPlan, setEnabledPlan] = useState<CustomerCapability | undefined>(enabledCustomerPlan);
  const [addCredits, setAddCredits] = useState<AddCredits | undefined>({
    mode: '',
    quantity: 0,
  });

  const customerOwnerEmail = customer?.ownerDetails?.email;
  const {
    id: currentStepId,
    Header,
    previousStep,
    nextStep,
    Form,
    isValid,
    canGoNext,
    onSubmit,
    canSkip,
  } = currentStep;
  const isFirstStep = initialStep.id === currentStepId;
  const isLastStep = currentStepId === COMPANY_PREVIEW_ROUTE;

  const formValues = {
    ...(customer || {}),
    fullCompanyName,
    legalBusinessEntity: {
      name: legalBusinessEntityName,
      registrationId: legalBusinessEntityRegId,
    },
    capabilities,
    companyDomains: companyDomainsCombined,
    isTest,
    users,
    capabilityDefinitions,
    enabledPlan,
    addCredits,
  };

  const redirectToCustomerList = () => history.push(CUSTOMERS_ROUTE);

  const showFormCompletionMessage = () => {
    success({
      title: 'Customer created!',
      content: `${fullCompanyName} has been created and users have been invited through email.`,
      onOk() {
        redirectToCustomerList();
      },
    });
  };

  const setUserPermissionDetails = ({ role, selected, id }: SetPermission) => {
    const clonedUsers = [...(users || [])];
    const userIndex = _findIndex(clonedUsers, { id });
    const permissions = _get(clonedUsers, `${userIndex}.permissions`) || [];
    if (selected) {
      _set(clonedUsers, `${userIndex}.permissions`, [...permissions, role]);
    } else {
      const updatedPermissions = _remove(permissions, ({ id }) => role.id !== id);
      _set(clonedUsers, `${userIndex}.permissions`, updatedPermissions);
    }
    setUsers(clonedUsers);
  };

  const setUserEmail = ({ id, value }: SetUserEmailProps) => {
    const clonedUsers = [...(users || [])];
    const userIndex = _findIndex(clonedUsers, { id });
    _set(clonedUsers, `${userIndex}.email`, value);
    setUsers(clonedUsers);
  };
  const addUser = (newUser?: UserWithRoles) => {
    const clonedUsers = [...(users || []), newUser || templateUser];
    setUsers(clonedUsers);
  };

  const removeUser = (id: string) => {
    const userIndex = _findIndex(users, { id });
    const clonedUsers = _remove(users, (_, index) => index !== userIndex);
    setUsers(clonedUsers);
  };

  const setDefaultPrimaryUserRoles = (roles: (Role | undefined)[]) => {
    setUsers([{ ...users[0], permissions: roles }] as UserWithRoles[]);
  };

  const initializeData = async () => {
    const availableRoles = await UserService.getRolesByCap(capabilities || []);
    const accessAdminRole = _find(availableRoles, { name: 'Access Admin' });
    const freightUserRole = _find(availableRoles, { name: 'Freight User' });
    const primaryUserDefaultRoles = [accessAdminRole, freightUserRole];
    setRolesList(availableRoles);

    if (customerId) {
      const users = await UserService.getBulkUsersByCustomerId(customerId)
        .then((response) => {
          setUsersLoaded(true);
          return response;
        })
        .catch((e) => {
          const responseError = _get(e, 'response.error', e);
          message.error({
            content: `Could not fetch users for this company - ${responseError}. If you think something is wrong, please contact support.`,
            duration: 1000,
          });
          setUsersLoaded(false);
          return [];
        });
      const primaryUserIndex = _findIndex(users, { email: customerOwnerEmail });
      const primaryUser = users[primaryUserIndex];

      if (users.length > 1 && primaryUser) {
        const otherUsers = _filter(users, (u) => u.email !== customerOwnerEmail);

        setUsers([primaryUser, ...otherUsers]);
      } else if (notEmpty(users)) {
        setUsers(users);
      } else if (_isEmpty(users) && primaryUserDefaultRoles) {
        setDefaultPrimaryUserRoles(primaryUserDefaultRoles);
      }
    } else if (primaryUserDefaultRoles) {
      setDefaultPrimaryUserRoles(primaryUserDefaultRoles);
    }
  };

  const onNextStep = () => {
    if (nextStep) {
      const next = _get(createCustomerWorkflow, nextStep);
      if (canSkip) {
        setCurrentStep(next);
        initializeData();
      } else if (canGoNext(formValues)) {
        setCurrentStep(next);
        initializeData();
      }
    }
  };

  const onGoBack = (selectedStepId: string) => {
    const workflowStepIds = _keys(createCustomerWorkflow);
    if (workflowStepIds.includes(selectedStepId)) {
      setCurrentStep(_get(createCustomerWorkflow, selectedStepId));
    }
  };

  const sendUserInvite = (userId: string) =>
    customerId &&
    UserService.sendInviteEmail(customerId, userId)
      .then(() => message.success('Successfully resent email invitation'))
      .catch((e) => message.error(`Could not resend email invitation - ${_get(e, 'response.error', e)}`));

  const onPreviousStep = () => {
    const prev = _get(createCustomerWorkflow, previousStep);
    if (prev) setCurrentStep(prev);
  };

  const containsDraftUser = _some(users, (u) => notEmpty(u.email));

  useEffect(() => {
    const isIntegrationTestCompany = legalBusinessEntityName.includes('integration tests');

    if (!isIntegrationTestCompany) {
      initializeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    if (users.length === 1) {
      const accessAdminRole = _find(rolesList, { name: 'Access Admin' });
      const freightUserRole = _find(rolesList, { name: 'Freight User' });
      const primaryUserDefaultRoles = [accessAdminRole, freightUserRole];
      setDefaultPrimaryUserRoles(primaryUserDefaultRoles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.length]);

  const handlers = {
    setFullCompanyName,
    setLegalBusinessEntityName,
    setLegalBusinessEntityRegId,
    setCompanyDomainsCombined,
    setIsTest,
    setUserPermissionDetails,
    setCapabilities,
    setEnabledPlan,
    setAddCredits,
    setUserEmail,
    onNextStep,
    onPreviousStep,
    addUser,
    canGoNext,
    removeUser,
    onGoBack,
    sendUserInvite,
  };

  return (
    <>
      <Row className='create-customer-page__header'>
        <Col span={1}>
          <BackButton
            isFirstStep={isFirstStep}
            isLastStep={isLastStep}
            editMode={!!editMode}
            previousStep={previousStep}
            onPreviousStep={onPreviousStep}
            onGoBack={onGoBack}
          />
        </Col>
        <Col span={23}>
          <Title level={3}>
            <Header {...{ ...formValues, editMode }} />
          </Title>
        </Col>
      </Row>
      <Form
        {...{
          ...currentStep,
          ...formValues,
          ...handlers,
          rolesList,
          editMode,
          capabilities,
          enabledPlan,
          customerId,
          usersLoaded,
          customerLoaded,
        }}
      />
      <Space className='form-navigation-btns'>
        {isLastStep && !editMode && (
          <Button
            loading={isSubmittingForm}
            disabled={(isValid && !isValid(formValues)) || isSubmittingForm}
            onClick={() => {
              if (onSubmit) {
                setIsSubmittingForm(true);
                onSubmit(formValues, { id: customerId })
                  .then((response) => {
                    setIsSubmittingForm(false);
                    showFormCompletionMessage();
                    if (updateCustomer) updateCustomer(response[0]);
                  })
                  .catch((e) => {
                    message.error(`Encounterd an error - ${_get(e, 'response.error', e)}`);
                    setIsSubmittingForm(false);
                  });
              }
            }}
            type='primary'
          >
            Submit
          </Button>
        )}
        {!isLastStep && !editMode && !canSkip && (
          <Button
            disabled={isValid && !isValid(formValues) && !canGoNext(formValues)}
            onClick={() => onNextStep()}
            type='primary'
          >
            Next
          </Button>
        )}

        {!isLastStep && !editMode && canSkip && !containsDraftUser && (
          <Button onClick={() => onNextStep()} type='primary'>
            Skip this step
          </Button>
        )}
        {!isLastStep && !editMode && canSkip && containsDraftUser && (
          <Button
            disabled={isValid && !isValid(formValues) && !canGoNext(formValues)}
            onClick={() => onNextStep()}
            type='primary'
          >
            Next
          </Button>
        )}
        {!isLastStep && editMode && (
          <Button
            loading={isSubmittingForm}
            disabled={(isValid && !isValid(formValues)) || isSubmittingForm || !canGoNext(formValues)}
            onClick={() => {
              if (onSubmit) {
                setIsSubmittingForm(true);
                onSubmit(formValues, { editMode, customer, usersLoaded, customerLoaded })
                  .then((response: [Customer, CustomerCapability[], User[]]) => {
                    setIsSubmittingForm(false);
                    if (updateCustomer) updateCustomer(response[0]);
                    message.success('Sucessfully updated customer!');
                  })
                  .catch((e) => {
                    setIsSubmittingForm(false);
                    message.error(`Encounterd an error - ${_get(e, 'response.error', e)}`);
                  });
              }
            }}
            type='primary'
          >
            Update
          </Button>
        )}
      </Space>
    </>
  );
};

export default CustomerOnboardingForm;
