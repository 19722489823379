import React, { useState, useEffect } from 'react';
import { Input, Button, Collapse } from 'antd';
// Local imports:
import { JsonEditor } from '@platform/pages/ScvCommander/styles';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { TitleBar, TitleButtonsContainer, TitleContainer, TitleWithoutMargin } from './styles';
import {
  getCargoById,
  getCargoEventById,
  getCarrierShipmentById,
  getLocationById,
  getTransportById,
  getTransportCallById,
  getTransportEventById,
} from '../../../services/ShipmentTrackerService/ShipmentTrackerService';
import { ScvCommanderEntityCargo } from './ScvCommanderEntityCargo';
import { ScvCommanderEntityCarrierShipment } from './ScvCommanderEntityCarrierShipment';
import { ScvEntityType, AddInspectionEntity } from './ScvCommanderEntityUtils';
import { ScvCommanderEntityCargoEvent } from './ScvCommanderEntityCargoEvent';
import { ScvCommanderEntityLocation } from './ScvCommanderEntityLocation';
import { ScvCommanderEntityTransport } from './ScvCommanderEntityTransport';
import { ScvCommanderEntityTransportCall } from './ScvCommanderEntityTransportCall';
import { ScvCommanderEntityTransportEvent } from './ScvCommanderEntityTransportEvent';

/**
 * Properties for this component
 */
interface ScvCommanderEntityProps {
  entityType: ScvEntityType;
  entityId: string;
  addInspectionEntityFunc: AddInspectionEntity;
  backgroundColor: string;
}

/**
 * Fetches the data from Shipment Tracker for a single entity.
 */
function getEntityByTypeAndId(entityType: ScvEntityType, entityId: string) {
  switch (entityType) {
    case ScvEntityType.cargo:
      return getCargoById(entityId);
    case ScvEntityType.cargoEvent:
      return getCargoEventById(entityId);
    case ScvEntityType.carrierShipment:
      return getCarrierShipmentById(entityId);
    case ScvEntityType.location:
      return getLocationById(entityId);
    case ScvEntityType.transport:
      return getTransportById(entityId);
    case ScvEntityType.transportCall:
      return getTransportCallById(entityId);
    case ScvEntityType.transportEvent:
      return getTransportEventById(entityId);
    default:
      return Promise.reject(new Error(`Cannot handle entity type ${entityType}`));
  }
}

/**
 * The SCV Commander entity component is a general purpose entity display component used for viewing an entity
 *
 * There are two main content areas inside this component. There is a human-readable entity display for quick viewing at the top,
 * plus the raw JSON from shipment tracker along with buttons to modify that JSON in shipment tracker below.
 */
export const ScvCommanderEntity = (props: ScvCommanderEntityProps): JSX.Element => {
  const [entityData, setEntityData] = useState<string>();

  useEffect(() => {
    getEntityByTypeAndId(props.entityType, props.entityId)
      .then((data) => setEntityData(JSON.stringify(data, null, 2)))
      .catch((error) => {
        console.error('Error fetching data:', error);
        setEntityData('');
      });
  }, [props.entityId]);

  /**
   * Render the raw JSON in a text area. This is the same for all entity types.
   */
  function renderEntityJson(json: string): JSX.Element {
    const { TextArea } = Input;
    return (
      <JsonEditor>
        <TextArea rows={10} cols={80} value={json} />
      </JsonEditor>
    );
  }

  /**
   * Render the specific component depending on the entityType.
   */
  function renderEntityPretty() {
    if (!entityData) {
      return <>...loading</>;
    }
    switch (props.entityType) {
      case ScvEntityType.cargo:
        return (
          <ScvCommanderEntityCargo
            entityId={props.entityId}
            entityData={JSON.parse(entityData)}
            addInspectionEntityFunc={props.addInspectionEntityFunc}
            backgroundColor={props.backgroundColor}
          />
        );
      case ScvEntityType.cargoEvent:
        return (
          <ScvCommanderEntityCargoEvent
            entityId={props.entityId}
            entityData={JSON.parse(entityData)}
            addInspectionEntityFunc={props.addInspectionEntityFunc}
            backgroundColor={props.backgroundColor}
          />
        );
      case ScvEntityType.transportEvent:
        return (
          <ScvCommanderEntityTransportEvent
            entityId={props.entityId}
            entityData={JSON.parse(entityData)}
            addInspectionEntityFunc={props.addInspectionEntityFunc}
            backgroundColor={props.backgroundColor}
          />
        );
      case ScvEntityType.carrierShipment:
        return (
          <ScvCommanderEntityCarrierShipment
            entityId={props.entityId}
            entityData={JSON.parse(entityData)}
            addInspectionEntityFunc={props.addInspectionEntityFunc}
            backgroundColor={props.backgroundColor}
          />
        );
      case ScvEntityType.location:
        return (
          <ScvCommanderEntityLocation
            entityId={props.entityId}
            entityData={JSON.parse(entityData)}
            addInspectionEntityFunc={props.addInspectionEntityFunc}
            backgroundColor={props.backgroundColor}
          />
        );
      case ScvEntityType.transport:
        return (
          <ScvCommanderEntityTransport
            entityId={props.entityId}
            entityData={JSON.parse(entityData)}
            addInspectionEntityFunc={props.addInspectionEntityFunc}
            backgroundColor={props.backgroundColor}
          />
        );
      case ScvEntityType.transportCall:
        return (
          <ScvCommanderEntityTransportCall
            entityId={props.entityId}
            entityData={JSON.parse(entityData)}
            addInspectionEntityFunc={props.addInspectionEntityFunc}
            backgroundColor={props.backgroundColor}
          />
        );
      default:
        return <p>Unsupported entity type - cannot render</p>;
    }
  }

  return (
    <div>
      <div>{entityData ? renderEntityPretty() : '...loading'}</div>
      <div>
        <TitleBar>
          <TitleContainer>
            <TitleWithoutMargin level={5}>Raw JSON</TitleWithoutMargin>
          </TitleContainer>
        </TitleBar>
        <Collapse>
          <CollapsePanel header='View and Update Raw JSON' key='rawJson' style={{ background: props.backgroundColor }}>
            <TitleBar>
              <TitleButtonsContainer>
                <Button htmlType='submit' type='primary' disabled>
                  Update
                </Button>
                <Button htmlType='submit' type='primary' disabled>
                  Delete
                </Button>
              </TitleButtonsContainer>
            </TitleBar>
            {entityData ? renderEntityJson(entityData) : '...loading'}
          </CollapsePanel>
        </Collapse>
      </div>
    </div>
  );
};
