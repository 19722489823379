import Button from 'antd/lib/button';
import React, { useState } from 'react';
import { Typography } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { CUSTOMERS_CREATE_ROUTE, USER_INVITE_ROUTE } from '@routes/constants';
import styled from 'styled-components';

const { Text } = Typography;

const Container = styled.div`
  position: relative;
  width: 100px;
  height: 40px;
`;

const ContainerItem = styled(Button)<{ $isTriggerItem?: boolean; index?: number; open?: boolean }>`
  background: #3b4858;
  color: white;
  border-radius: 8px !important;
  width: 100% !important;
  position: absolute;
  bottom: ${(props) => (props.index && props.open ? props.index * 45 : 0)}px;
  right: 0px;
  opacity: ${(props) => (!props.$isTriggerItem || !props.open ? 1 : 0.8)};
  z-index: ${(props) => (props.$isTriggerItem ? 1 : 0)};
  transition: 'all .3s';

  &:hover,
  &:focus {
    background: #3b4858;
    color: white;
    box-shadow: none;
    border: none;
  }
`;

export const CommonCreateButton = ({ style }: { style?: React.CSSProperties }) => {
  const [open, setOpen] = useState(false);
  const options = [
    {
      label: 'Customer',
      href: CUSTOMERS_CREATE_ROUTE,
    },
    {
      label: 'User',
      href: USER_INVITE_ROUTE,
    },
  ];

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 40,
        right: 40,
        zIndex: 1,
        ...(style || {}),
      }}
    >
      <Container>
        {options.reverse().map((option, index) => (
          <ContainerItem size='large' href={option.href} index={index + 1} open={open} key={option.label}>
            <Text style={{ color: 'inherit', fontSize: 14 }}>{option.label}</Text>
          </ContainerItem>
        ))}
        <ContainerItem
          key='trigger'
          size='large'
          onClick={() => setOpen(!open)}
          icon={open ? <CloseOutlined /> : <PlusOutlined />}
          $isTriggerItem
          open={open}
        >
          {!open && <Text style={{ color: 'inherit' }}>New</Text>}
        </ContainerItem>
      </Container>
    </div>
  );
};
