import React, { ReactElement, ReactNode } from 'react';
import { Layout, Empty, Spin } from 'antd';
import { CommonCreateButton } from '@components/CommonCreateButton';
import SideBar from '@components/SideBar';
import './style.less';

const { Content, Header } = Layout;

type PageLayoutProps = {
  Actions?: ReactElement;
  children?: ReactNode;
  hasContent?: boolean;
  TitleComponent?: ReactElement;
  FooterComponent?: ReactElement;
  isLoading?: boolean;
  showCommonCreateButton?: boolean;
  noPadding?: boolean;
  createButtonStyle?: React.CSSProperties;
};

const PageLayout = ({
  children,
  Actions,
  hasContent = true,
  TitleComponent,
  FooterComponent,
  isLoading,
  showCommonCreateButton,
  noPadding,
  createButtonStyle,
}: PageLayoutProps): ReactElement => {
  const renderPageStatus = () =>
    isLoading ? (
      <Spin size='large' className='bo-empty-content-icon' aria-label='Loading content' />
    ) : (
      <Empty className='bo-empty-content-icon' />
    );

  return (
    <Layout className='bo__page-layout' hasSider>
      <nav className='bo-side-bar'>
        <SideBar />
      </nav>
      <div className='bo-site-layout'>
        {Actions ? <Header className='header'>{Actions}</Header> : null}
        {hasContent ? (
          <Content className='content' style={{ padding: noPadding ? 0 : 15, height: '100vh' }}>
            <header className='bo-header-container'>{TitleComponent && TitleComponent}</header>
            {children}
            {FooterComponent && FooterComponent}
          </Content>
        ) : (
          renderPageStatus()
        )}
      </div>
      {showCommonCreateButton && <CommonCreateButton style={createButtonStyle} />}
    </Layout>
  );
};

export default PageLayout;
