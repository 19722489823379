import React, { ReactElement } from 'react';
import _get from 'lodash/get';
import { Checkbox, Form, Input, Popover, Radio, Typography } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { requiredRule } from '@utils/forms/rules';
import { CustomerFormProps } from '@beacon-types/customer';
import { CustomerCapabilityType } from '@beacon-devops/customer-service-client';
import {
  availableCapabilities,
  availableCustomerAddOns,
  getEnabledCustomerPlan,
} from '@utils/customer/customerCapabilities';
import { notEmpty } from '@utils/generalUtilities';
import { Box } from '@beacon-devops/components';
import { Mode } from '@beacon-devops/graphql-typescript-client';
import { CustomerStatusTag } from './partials';
import './styles.less';
import { getCapabilityById } from './utils';

const { Title } = Typography;

const CustomerFormV2 = (props: CustomerFormProps): ReactElement => {
  const {
    fullCompanyName,
    setFullCompanyName,
    companyDomains,
    setCompanyDomainsCombined,
    isTest,
    setIsTest,
    setCapabilities,
    capabilities,
    status,
    legalBusinessEntity,
    setLegalBusinessEntityName,
    setLegalBusinessEntityRegId,
    capabilityDefinitions,
    setEnabledPlan,
    addCredits,
    setAddCredits,
  } = props;

  const setPlanWithId = (checked: boolean, id: string) => {
    // only one plan can be selected at one time so this disables the unselected plans
    let otherPlanIds = availableCapabilities(capabilityDefinitions || [], CustomerCapabilityType.Plan)
      .map((p) => p.id)
      .filter((p) => p !== id);
    otherPlanIds?.forEach((planId) => {
      let planToDisable = getCapabilityById(planId, capabilities);
      if (planToDisable !== undefined) {
        planToDisable.enabled = false;
      }
    });
    let selectedPlan = getCapabilityById(id, capabilities);
    if (selectedPlan) {
      selectedPlan.enabled = checked;
      setEnabledPlan(selectedPlan);
    }
    return setCapabilities(capabilities);
  };

  const setAddOnWithId = (checked: boolean, id: string) => {
    let capability = getCapabilityById(id, capabilities);
    if (capability) {
      capability.enabled = checked;
    }
    return setCapabilities(capabilities);
  };

  const setAddOnWithQuantityWithId = (quantity: number, id: string) => {
    let capability = getCapabilityById(id, capabilities);
    if (capability) {
      capability.quantity = quantity;
      capability.enabled = true;
    }
    return setCapabilities(capabilities);
  };

  return (
    <Form
      className='create-customer-form'
      labelCol={{ span: 6 }}
      labelAlign='left'
      wrapperCol={{ span: 18 }}
      layout='horizontal'
    >
      <Title level={5}> Company Details</Title>
      <Form.Item label='Company Name' name='companyName' rules={[requiredRule]}>
        <Input
          placeholder='MUI DISTRIBUTORS'
          defaultValue={fullCompanyName}
          onChange={(e) => {
            setFullCompanyName(e.target.value);
          }}
        />
      </Form.Item>

      <Form.Item label='Additional Company Domains'>
        <TextArea
          placeholder='domain1.com,domain2.co.uk'
          defaultValue={companyDomains?.join(',')}
          onChange={(e) => {
            const { value } = e.target;
            if (notEmpty(value)) {
              setCompanyDomainsCombined(value.split(','));
            } else {
              setCompanyDomainsCombined([]);
            }
          }}
        />
      </Form.Item>
      {status && (
        <Form.Item label='Status'>
          <CustomerStatusTag status={status} />
        </Form.Item>
      )}
      <Form.Item wrapperCol={{ offset: 6 }}>
        <Checkbox
          defaultChecked={isTest}
          onChange={(e) => {
            setIsTest(e.target.checked);
          }}
        >
          {' '}
          Flag as Test Account{' '}
          <Popover
            title='What is a test account ?'
            content='Test account gives the user to ability to create test payments and test the platform without any real events. '
            trigger='hover'
            placement='bottom'
            overlayClassName='test-account-popover'
          >
            <InfoCircleFilled />
          </Popover>
        </Checkbox>
      </Form.Item>

      <Title level={5}> Legal Entity</Title>
      <Form.Item label='Legal Entity Name' name='legalBusinessEntityName' rules={[requiredRule]}>
        <Input
          placeholder='MUI CO LTD'
          defaultValue={_get(legalBusinessEntity, 'name')}
          onChange={(e) => {
            setLegalBusinessEntityName(e.target.value);
          }}
        />
      </Form.Item>
      <Form.Item label='Legal Entity Reg. No' name='legalBusinessEntityRegId' rules={[requiredRule]}>
        <Input
          defaultValue={_get(legalBusinessEntity, 'registrationId')}
          placeholder='ACF34674432342'
          onChange={(e) => {
            setLegalBusinessEntityRegId(e.target.value);
          }}
        />
      </Form.Item>
      <br />
      <Title level={5}> Customer Capabilities</Title>
      <Form.Item label='Tracking Plan' name='plans' rules={[requiredRule]} style={{ marginBottom: '8px' }}>
        <Radio.Group
          defaultValue={getEnabledCustomerPlan(capabilityDefinitions || [], capabilities || [])?.id}
          buttonStyle='solid'
        >
          {availableCapabilities(capabilityDefinitions || [], CustomerCapabilityType.Plan).map((plan) => (
            <Radio.Button
              key={plan.id}
              value={plan.id}
              onChange={(e) => {
                setPlanWithId(e.target.checked, plan.id);
              }}
            >
              {plan.name}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item label='Add-ons' name='addons'>
        {availableCustomerAddOns(capabilityDefinitions || []).map((addon) => (
          <Form.Item style={{ marginBottom: '8px' }} key={addon.id}>
            {addon.type === CustomerCapabilityType.AddOn && (
              <Checkbox
                defaultChecked={getCapabilityById(addon.id, capabilities)?.enabled || false}
                onChange={(e) => {
                  setAddOnWithId(e.target.checked, addon.id);
                }}
              >
                {addon.name}
              </Checkbox>
            )}
            {addon.type === CustomerCapabilityType.AddOnWithQuantity && (
              <Box display='flex' alignItems='center'>
                {addon.limits?.map((limit) => (
                  <Box width='60px' mr={3} key={limit.type}>
                    <Input
                      type='number'
                      defaultValue={getCapabilityById(addon.id, capabilities)?.quantity || 0}
                      onChange={(e) =>
                        setAddOnWithQuantityWithId(e.target.value ? parseInt(e.target.value) : 0, addon.id)
                      }
                    />
                  </Box>
                ))}{' '}
                {addon.name}
              </Box>
            )}
          </Form.Item>
        ))}
      </Form.Item>

      <br />
      <Title level={5}>Customer Credits</Title>
      <Form.Item label='Add credits mode' name='creditsMode' style={{ marginBottom: '8px' }}>
        <Radio.Group defaultValue={Mode.Ocean} buttonStyle='solid'>
          {[Mode.Ocean, Mode.Air, Mode.Road].map((mode) => (
            <Radio.Button
              key={mode}
              value={mode}
              onChange={(e) => {
                setAddCredits({
                  quantity: addCredits?.quantity || 0,
                  mode: e.target.value,
                });
              }}
            >
              {mode}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item label='Add credits quantity' name='creditsQuantity' style={{ marginBottom: '8px' }}>
        <Input
          defaultValue={0}
          type='number'
          onChange={(e) => {
            setAddCredits({
              mode: addCredits?.mode || Mode.Ocean,
              quantity: e.target.value ? parseInt(e.target.value) : 0,
            });
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default CustomerFormV2;
