import {
  bulkPurgeCarrierShipmentData,
  purgeCarrierShipmentData,
} from '@services/ShipmentTrackerService/ShipmentTrackerService';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

export const usePurgeShipmentData = (carrierShipmentId: string) => {
  return useMutation<void, AxiosError>(async () => {
    await purgeCarrierShipmentData(carrierShipmentId);
  });
};

export const useBulkPurgeShipmentData = (carrierShipmentIds: string[], onSuccess: () => void, onError: () => void) => {
  return useMutation<void, AxiosError>(
    async () => {
      await bulkPurgeCarrierShipmentData(carrierShipmentIds);
    },
    {
      onSuccess,
      onError,
    },
  );
};
