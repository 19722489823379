import React from 'react';
import _findIndex from 'lodash/findIndex';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import { InfoCircleFilled, CloseOutlined } from '@ant-design/icons';

import { TablePaginationConfig, ColumnType } from 'antd/lib/table';
import { Checkbox, Input, Typography, Form, Button, Tooltip, Switch } from 'antd';

import { Role } from '@beacon-devops/customer-service-client';
import { requiredRule, emailRule } from '@utils/forms/rules';
import { notEmpty } from '@utils/generalUtilities';
import { emailRegex } from '@utils/forms/validation.regexp';
import { GetInviteUserColumnsProps, UserWithRoles } from '@beacon-types/customer';

const orderColumns = (
  cols: {
    title?: JSX.Element;
    className?: string;
    dataIndex?: string[] | string;
    name?: string;
    key: string;
    width?: number;
    render: (value: Role[] | string, record: UserWithRoles, index: number) => JSX.Element;
  }[],
) => {
  if (cols) {
    const accessAdminColumnIndex = _findIndex(cols, { name: 'Access Admin' });
    const accessAdminCol = cols[accessAdminColumnIndex];
    const otherColumns = _filter(cols, (col) => col.name !== 'Access Admin');

    return accessAdminCol ? [...otherColumns, accessAdminCol] : [...otherColumns];
  }
  return [];
};

const { Text } = Typography;
interface EmailInputProps {
  id: string;
  dataIndex: string;
  onChange?: ({ id, value }: { id: string; value: string }) => void;
  value: string;
  disabled?: boolean;
}
const EmailInput = ({ id, dataIndex, onChange, value, disabled = false }: EmailInputProps) => {
  const initialValues = { [dataIndex]: value };
  return (
    <Form initialValues={initialValues}>
      <Form.Item hasFeedback name={dataIndex} rules={[requiredRule, emailRule]}>
        <Input
          disabled={disabled}
          type='email'
          className='user-email-input'
          key={dataIndex}
          placeholder='Enter email address'
          value={value}
          onChange={(e) => {
            if (onChange) onChange({ id, value: e.target.value });
          }}
        />
      </Form.Item>
    </Form>
  );
};

const RoleCheckbox = ({
  onChange,
  value,
  disabled,
  role,
  user,
}: {
  onChange: ({ role, user, selected, id }: { role: Role; user: UserWithRoles; selected: boolean; id: string }) => void;
  value: boolean;
  disabled: boolean;
  role: Role;
  user: UserWithRoles;
}) => {
  return (
    <Checkbox
      disabled={disabled}
      checked={value}
      onChange={(e) => {
        if (onChange) onChange({ role, user, selected: e.target.checked, id: user.id });
      }}
    />
  );
};

const RoleSwitch = ({
  onChange,
  value,
  disabled,
  role,
  user,
}: {
  onChange: ({ role, user, selected, id }: { role: Role; user: UserWithRoles; selected: boolean; id: string }) => void;
  value: boolean;
  disabled: boolean;
  role: Role;
  user: UserWithRoles;
}) => {
  return (
    <Switch
      size='small'
      disabled={disabled}
      checked={value}
      onChange={(checked) => {
        if (onChange) onChange({ role, user, selected: checked, id: user.id });
      }}
    />
  );
};

export const getColumns = ({
  setUserPermissionDetails,
  setUserEmail,
  removeUser,
  rolesList,
  permissionDataIndex = 'permissions',
  showUserEmail = true,
  isSCFCustomer = false,
  totalItems,
  disableDelete = false,
}: GetInviteUserColumnsProps): ColumnType<UserWithRoles>[] => {
  const getRolesColumns = () => {
    return rolesList.map((role) => {
      const { id: roleId, name, description } = role;
      const isAccessAdminInput = name === 'Access Admin';
      const isPaymentRoleInput = name === 'Payments Admin' || name === 'Payments User';
      const isPaymentColumnDisabled = !isSCFCustomer && isPaymentRoleInput;
      return {
        title: (
          <Text>
            {name}{' '}
            <Tooltip placement='top' title={description}>
              <InfoCircleFilled />
            </Tooltip>
          </Text>
        ),
        className: `${isPaymentColumnDisabled ? 'role-disabled ' : ''}${
          !isSCFCustomer && isPaymentRoleInput ? 'not-scf-customer' : ''
        }`,
        dataIndex: [permissionDataIndex],
        name,
        key: roleId,
        width: 80,
        render: (value: Role[] | string, record: UserWithRoles) => {
          const permissions = value as Role[];
          const permissionIndex = permissions && _findIndex(permissions, { id: roleId });
          const hasRoleSelected = permissions && notEmpty(permissions[permissionIndex]);
          const isEmailValid = new RegExp(emailRegex).test(record.email);
          const hasDefaultCheckedValues = showUserEmail ? !isPaymentRoleInput : false;
          let disabled;
          if (record.userGroup === 'Internal') {
            disabled = true;
          } else {
            disabled = showUserEmail
              ? !isEmailValid || isPaymentColumnDisabled || totalItems === 1
              : _isEmpty(record.email) || isPaymentColumnDisabled || hasDefaultCheckedValues;
          }
          return isAccessAdminInput ? (
            <div>
              <RoleSwitch
                disabled={disabled}
                value={hasRoleSelected}
                role={role}
                user={record}
                onChange={setUserPermissionDetails}
              />
            </div>
          ) : (
            <div>
              <RoleCheckbox
                disabled={disabled}
                value={hasRoleSelected}
                role={role}
                user={record}
                onChange={setUserPermissionDetails}
              />
            </div>
          );
        },
      };
    });
  };

  const columns = orderColumns(getRolesColumns());

  const deleteUserColumn = {
    key: 'removeUserButton',
    width: 80,
    render: (_: string | Role[], record: UserWithRoles) => {
      if (disableDelete) return <></>;
      return (
        <Button
          className='delete-user-btn'
          type='link'
          size='small'
          onClick={() => {
            if (removeUser) removeUser(record.id);
          }}
        >
          <CloseOutlined />
        </Button>
      );
    },
  };

  if (showUserEmail) {
    columns.unshift({
      title: <Text>User</Text>,
      dataIndex: 'email',
      width: 230,
      key: 'user-email',
      name: 'user',
      className: 'user',
      render: (value, record: UserWithRoles, index: number) => {
        return (
          <div className='user-email' key={`user-email-${index}`}>
            <EmailInput id={record.id} dataIndex={`${index}.email`} value={value as string} onChange={setUserEmail} />
          </div>
        );
      },
    });

    columns.push(deleteUserColumn);
  }

  return columns;
};

export const pagination: TablePaginationConfig = {
  position: ['bottomRight'],
  defaultPageSize: 20,
  hideOnSinglePage: true,
};
