import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { CUSTOMERS_ROUTE } from '@routes/constants';
import { COMPANY_PREVIEW_ROUTE } from './customerOnboarding.definition';

type Props = {
  isFirstStep: boolean;
  isLastStep: boolean;
  editMode: boolean;
  previousStep: string;
  onPreviousStep: () => void;
  onGoBack: (selectedStepId: string) => void;
};

export const BackButton = ({ isFirstStep, isLastStep, editMode, previousStep, onPreviousStep, onGoBack }: Props) => {
  if (isFirstStep && !editMode)
    return (
      <Link className='ant-btn ant-btn-text bo-action-icon' to={previousStep}>
        <ArrowLeftOutlined />
      </Link>
    );

  if (!isFirstStep && !editMode)
    return (
      <Button onClick={onPreviousStep} type='link' className='ant-btn ant-btn-text bo-action-icon'>
        <ArrowLeftOutlined />
      </Button>
    );

  return !isLastStep ? (
    <Button type='link' className='ant-btn ant-btn-text bo-action-icon' onClick={() => onGoBack(COMPANY_PREVIEW_ROUTE)}>
      <ArrowLeftOutlined />
    </Button>
  ) : (
    <Link className='ant-btn ant-btn-text bo-action-icon' to={CUSTOMERS_ROUTE}>
      <ArrowLeftOutlined />
    </Link>
  );
};
