import { CalendarOutlined, FilterFilled, SearchOutlined } from '@ant-design/icons';
import React from 'react';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { ColumnType } from 'antd/lib/table';
import { Cargo, MilestoneType } from '@beacon-devops/graphql-typescript-client';
import { Customer } from '@beacon-devops/customer-service-client';
import { CargoSortColumns } from '@beacon-types/cargo/cargoFilters';
import { CUSTOMER_DETAIL_ROUTE } from '@routes/constants';
import { AIR_CONTAINER_PATH } from '@platform/routes/paths';
import { StringTemplate } from '@components/common/TableView/partials';
import { get } from 'lodash';
import { mapMilestoneEventDateTime } from '@platform/utils';
import moment from 'moment/moment';
import { ShipmentReferenceTypeCode } from '@beacon-devops/graphql-typescript-client/dist/src/gql/graphql';

export enum AirCargoListColumnKey {
  customerId = 'customerId',
  status = 'status',
  carrierShipmentId = 'carrierShipmentId',
  mawb = 'mawb',
  flightNumber = 'flightNumber',
  carrierName = 'carrierName',
  vesselName = 'vesselName',
  departurePort = 'departurePort',
  arrivalPort = 'arrivalPort',
  departureDate = 'departureDate',
  arrivalDate = 'arrivalDate',
}

enum FilterIconType {
  Date = 'date',
  Filter = 'Filter',
  Search = 'Search',
}

const dateFormat = 'Do MMM, YYYY HH:mmA';

const getFilterColumnIcon = (
  columnKey: AirCargoListColumnKey,
  filterIconType: FilterIconType,
  onClick?: (columnKey: AirCargoListColumnKey) => void,
  filtered?: boolean,
) => () => {
  let Icon;
  let fontSize = '14px';
  switch (filterIconType) {
    case FilterIconType.Date:
      Icon = CalendarOutlined;
      break;
    case FilterIconType.Filter:
      Icon = FilterFilled;
      break;
    case FilterIconType.Search:
    default:
      Icon = SearchOutlined;
  }

  const clickHandler = () => onClick && onClick(columnKey);

  return (
    <Icon
      onClick={clickHandler}
      style={{
        color: filtered ? '#1890ff' : undefined,
        fontSize,
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    />
  );
};

const tooltipLink = (title = '', to = '', value = '') => (
  <Tooltip placement='bottom' title={title}>
    <Link to={to} target='_blank'>
      <span>{value}</span>
    </Link>
  </Tooltip>
);

interface GetContainerColumnOptions {
  customers: Record<string, Customer | undefined>;
  filterIconClickHandler: (columnKey: AirCargoListColumnKey) => void;
  isFilterSelected: (columnKey: CargoSortColumns) => boolean;
}

export const getColumns = ({
  customers,
  filterIconClickHandler,
  isFilterSelected,
}: GetContainerColumnOptions): ColumnType<Cargo>[] => {
  return [
    {
      title: 'Customer',
      className: 'customerId',
      dataIndex: 'customerId',
      key: AirCargoListColumnKey.customerId,
      fixed: 'left',
      sorter: true,
      filterDropdown: true,
      filterDropdownVisible: false,
      filterIcon: getFilterColumnIcon(
        AirCargoListColumnKey.customerId,
        FilterIconType.Search,
        filterIconClickHandler,
        isFilterSelected('customerId'),
      ),
      render: (val) =>
        tooltipLink('Edit customer', CUSTOMER_DETAIL_ROUTE.replace(':id', val), customers[val]?.fullCompanyName ?? val),
    },
    {
      title: 'MAWB (or Cargo ID)',
      className: 'mawb',
      dataIndex: 'mawb',
      key: AirCargoListColumnKey.mawb,
      sorter: true,
      filterDropdown: true,
      filterDropdownVisible: false,
      filterIcon: getFilterColumnIcon(AirCargoListColumnKey.mawb, FilterIconType.Filter, filterIconClickHandler),
      render: (val, cargo) => {
        const shipmentReference = cargo.shipmentReferences?.find((ref) => ref.type === ShipmentReferenceTypeCode.Mawb);
        return tooltipLink(
          'Edit cargo',
          AIR_CONTAINER_PATH.replace(':customerId', cargo.customerId).replace(':cargoId', cargo.id),
          shipmentReference?.value || cargo.carrierShipmentId,
        );
      },
    },
    {
      title: 'Status',
      className: 'status',
      dataIndex: 'status',
      key: AirCargoListColumnKey.status,
      sorter: true,
      filterDropdown: true,
      filterDropdownVisible: false,
      filterIcon: getFilterColumnIcon(AirCargoListColumnKey.status, FilterIconType.Filter, filterIconClickHandler),
      render: (val) => val,
    },
    {
      title: 'Airline',
      dataIndex: 'carrierName',
      className: 'carrierName',
      key: AirCargoListColumnKey.carrierName,
      sorter: true,
      filterDropdown: true,
      filterDropdownVisible: false,
      filterIcon: getFilterColumnIcon(
        AirCargoListColumnKey.carrierName,
        FilterIconType.Filter,
        filterIconClickHandler,
        isFilterSelected('carrierName'),
      ),
      render: (val, cargo) => StringTemplate(get(cargo, 'carrier.displayName', '')),
    },
    {
      title: 'Flight #',
      className: 'flightNumber',
      dataIndex: 'flightNumber',
      key: AirCargoListColumnKey.flightNumber,
      sorter: true,
      filterDropdown: true,
      filterDropdownVisible: false,
      filterIcon: getFilterColumnIcon(
        AirCargoListColumnKey.flightNumber,
        FilterIconType.Filter,
        filterIconClickHandler,
      ),
      render: (val, cargo) => {
        return StringTemplate(get(cargo, 'transportSummary.currentJourneyNumber', ''));
      },
    },
    {
      title: 'POL',
      dataIndex: 'departurePort',
      className: 'departurePort',
      key: AirCargoListColumnKey.departurePort,
      sorter: true,
      filterDropdown: true,
      filterDropdownVisible: false,
      filterIcon: getFilterColumnIcon(
        AirCargoListColumnKey.departurePort,
        FilterIconType.Filter,
        filterIconClickHandler,
        isFilterSelected('departurePort'),
      ),
      render: (val, cargo) => StringTemplate(`${get(cargo, 'transportSummary.primaryLoadLocation.name', '')}`),
    },
    {
      title: 'POD',
      dataIndex: 'arrivalPort',
      className: 'arrivalPort',
      key: AirCargoListColumnKey.arrivalPort,
      sorter: true,
      filterDropdown: true,
      filterDropdownVisible: false,
      filterIcon: getFilterColumnIcon(
        AirCargoListColumnKey.arrivalPort,
        FilterIconType.Filter,
        filterIconClickHandler,
        isFilterSelected('arrivalPort'),
      ),
      render: (val, cargo) => StringTemplate(`${get(cargo, 'transportSummary.primaryDischargeLocation.name', '')}`),
    },
    {
      title: 'Departure Date',
      dataIndex: 'departureDate',
      className: 'departureDate',
      key: AirCargoListColumnKey.departureDate,
      sorter: true,
      filterDropdown: true,
      filterDropdownVisible: false,
      filterIcon: getFilterColumnIcon(
        AirCargoListColumnKey.departureDate,
        FilterIconType.Date,
        filterIconClickHandler,
        isFilterSelected('departureDate'),
      ),
      render: (val, cargo) => {
        const milestoneSummary = get(cargo, `milestoneViews.summary`, []);
        const actualDate = mapMilestoneEventDateTime(milestoneSummary, MilestoneType.DepartedOrigin);
        const estimatedDate = mapMilestoneEventDateTime(milestoneSummary, MilestoneType.EtdAtOrigin);
        const displayDate = actualDate.timestamp || actualDate.date || estimatedDate.timestamp || estimatedDate.date;
        return StringTemplate(
          displayDate
            ? moment(displayDate)
                .tz(actualDate.zone || estimatedDate.zone || 'UTC')
                .format(dateFormat)
            : '',
        );
      },
    },
    {
      title: 'Arrival Date',
      dataIndex: 'arrivalDate',
      className: 'arrivalDate',
      key: AirCargoListColumnKey.arrivalDate,
      sorter: true,
      filterDropdown: true,
      filterDropdownVisible: false,
      filterIcon: getFilterColumnIcon(
        AirCargoListColumnKey.arrivalDate,
        FilterIconType.Date,
        filterIconClickHandler,
        isFilterSelected('arrivalDate'),
      ),
      render: (val, cargo) => {
        const milestoneSummary = get(cargo, `milestoneViews.summary`, []);
        const actualDate = mapMilestoneEventDateTime(milestoneSummary, MilestoneType.ArrivedAtDest);
        const estimatedDate = mapMilestoneEventDateTime(milestoneSummary, MilestoneType.EtaAtDest);
        const displayDate = actualDate.timestamp || actualDate.date || estimatedDate.timestamp || estimatedDate.date;
        return StringTemplate(
          displayDate
            ? moment(displayDate)
                .tz(actualDate.zone || estimatedDate.zone || 'UTC')
                .format(dateFormat)
            : '',
        );
      },
    },
  ];
};
