import React, { FC, useEffect, useMemo, useState } from 'react';
import PageLayout from '@components/PageLayout';
import { useSearchCargoForAllCustomers } from '@queries/graphql';
import { Cargo, Mode, SearchConditionType, WeightUnit } from '@beacon-devops/graphql-typescript-client';
import { useHistory, useParams } from 'react-router-dom';
import {
  CDetailsCard,
  CDetailsCardContentLayout,
  CDetailsTimelineItem,
  CDetailsTimelinePoint,
  ErrorWrapper,
  FormLabel,
  FormSection,
  LoadingCentered,
} from '@platform/pages/AirContainer/styles';
import { ScvContainerDate } from '@platform/components/ContainerDate';
import { Alert, Input, message, Modal, Spin, Timeline } from 'antd';
import { get } from 'lodash';
import { buildEventProps, LocationType, mapSCVCargoFormatToForm } from '@platform/utils';
import PortSearch from '@platform/components/PortSearch';
import { Controller, FormProvider, UseControllerProps, useForm } from 'react-hook-form';
import { SCVContainerFormValues } from '@platform/types';
import ScvContainerDetailsHeader from '@platform/components/ScvContainerDetailsHeader';
import { useCustomer } from '@queries/customers';
import { useDeleteCargoMutation, useManualCTATrackingMutation, usePatchCargoDataMutation } from '@queries/containers';
import { Box } from '@beacon-devops/components';
import CarrierSearch from '@platform/components/CarrierSearch';
import TranshipmentFieldArray from '@platform/pages/OceanContainer/TranshipmentFieldArray';
import TimelineMilestoneIcon from '@assets/icons/Timeline_milestone.svg';
import { DevTool } from '@hookform/devtools';
import { ContainerDeleteModal } from '@platform/components/ContainerDeleteModal';
import { handleCargoAirDelete } from '@platform/utils/deleteCargo';
import { patchArrivalZone, patchDepartureZone } from '@platform/pages/OceanContainer/utils/OceanContainerUtils';
import { ShipmentDates } from '@platform/pages/OceanContainer/ShipmentDates';
import { basePathAir } from '@platform/routes/paths';
import { handleCargoFormSubmit } from '@platform/utils/updateCargoOcean';
import CargoValidationErrorSection from '@platform/components/CargoValidationErrorSection';
import { getValidEventRanges } from '@platform/utils/handleValidation';
import { ShipmentPurgeModal } from '@platform/components/ShipmentPurgeModal';
import { usePurgeShipmentData } from '@queries/shipmentTracker/usePurgeShipmentData';

export interface CargoPageRouteParams {
  customerId: string;
  cargoId: string;
}

const DEPARTURE_PORT_FIELD_NAME = 'departurePort';
const ARRIVAL_PORT_FIELD_NAME = 'arrivalPort';

export const AirContainer: FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isJSONModalOpen, setJSONModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const { customerId, cargoId } = useParams<CargoPageRouteParams>();
  const [deletedEventIds, setDeletedEventIds] = useState<string[]>([]);
  const [isPurgeModalOpen, setPurgeModalOpen] = useState(false);

  const history = useHistory();

  const addDeletedEvent = (eventId: string) => {
    setDeletedEventIds([...deletedEventIds, eventId]);
  };
  const removeDeletedEvent = (eventId: string) => {
    setDeletedEventIds(deletedEventIds.filter((id) => id !== eventId));
  };

  const { data: customer } = useCustomer(customerId);

  const {
    data: searchData,
    isError,
    error,
    isLoading: isLoadingCargo,
    refetch: refetchCargo,
  } = useSearchCargoForAllCustomers({
    criteria: [
      {
        field: 'id',
        condition: SearchConditionType.Equals,
        values: [cargoId],
      },
    ],
  });

  const cargo = get(searchData, 'cargos[0]', {}) as Cargo;

  const {
    mutate: purgeShipmentData,
    isSuccess: isPurgeShipmentSuccess,
    error: purgeShipmentError,
  } = usePurgeShipmentData(cargo?.carrierShipmentId || '');

  const cargoInFormData = useMemo(() => {
    if (cargo) {
      return mapSCVCargoFormatToForm(cargo, cargo.carrierShipmentId, customerId);
    }
    return null;
  }, [JSON.stringify(cargo), cargo.carrierShipmentId, customerId]);

  const {
    mutate: patchCargoData,
    isLoading: isLoadingPatchCargoData,
    isSuccess: isPatchCargoSuccess,
    error: patchCargoDataError,
  } = usePatchCargoDataMutation(cargo.carrierShipmentId, customerId);

  const {
    mutate: deleteCargoData,
    isLoading: isLoadingDeleteCargoData,
    isSuccess: isDeleteCargoSuccess,
    error: deleteCargoDataError,
  } = useDeleteCargoMutation(cargo.carrierShipmentId, customerId);

  const {
    mutate: updateManualTracking,
    isLoading: isUpdatingCTATracking,
    error: updateCTATrackingError,
  } = useManualCTATrackingMutation(cargoInFormData?.identifier || '', Mode.Air);

  const formMethods = useForm<SCVContainerFormValues>({ mode: 'all' });
  const {
    watch,
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, dirtyFields },
  } = formMethods;

  const watchedFormData = watch();

  const canSubmit = cargoInFormData?.mawb;

  const watchPOL = watch(DEPARTURE_PORT_FIELD_NAME);
  const watchPOD = watch(ARRIVAL_PORT_FIELD_NAME);

  useEffect(() => {
    const formValues = formMethods.getValues();
    const newZone = get(formValues, 'departurePort.zone');
    if (!cargoInFormData || !newZone) return;
    patchDepartureZone(newZone, formValues, setValue);
  }, [watchPOL]);

  useEffect(() => {
    const formValues = formMethods.getValues();
    const newZone = get(formValues, 'arrivalPort.zone');
    if (!cargoInFormData || !newZone) return;
    patchArrivalZone(newZone, formValues, setValue);
  }, [watchPOD]);

  useEffect(() => {
    if (cargoInFormData) {
      reset({
        ...cargoInFormData,
      });
    }
  }, [cargoInFormData]);

  useEffect(() => {
    if (isDeleteCargoSuccess) {
      messageApi
        .open({
          type: 'success',
          content: 'Air cargo deleted successfully',
          duration: 3,
        })
        .then(() => {
          history.push(basePathAir);
        });
    }
  }, [isDeleteCargoSuccess]);

  useEffect(() => {
    if (isPatchCargoSuccess) {
      refetchCargo();

      messageApi.open({
        type: 'success',
        content: 'Air cargo updated successfully',
        duration: 3,
      });
    }
  }, [isPatchCargoSuccess]);

  useEffect(() => {
    if (isPurgeShipmentSuccess) {
      setTimeout(() => {
        setPurgeModalOpen(false);

        messageApi.open({
          type: 'success',
          content: 'Air cargo purged successfully',
          duration: 3,
        });

        refetchCargo();
      }, 3500); // needed, otherwise won't get back updated cargo after refetch
    }
  }, [isPurgeShipmentSuccess]);

  useEffect(() => {
    if (purgeShipmentError) {
      setPurgeModalOpen(false);
    }
  }, [purgeShipmentError]);

  if (isLoadingCargo || isLoadingPatchCargoData || isLoadingDeleteCargoData) {
    return (
      <PageLayout>
        <LoadingCentered>
          <Spin size='large' className='bo-empty-content-icon' aria-label='Loading scv content' />
        </LoadingCentered>
      </PageLayout>
    );
  }

  const eventValidationRanges = getValidEventRanges({
    formData: watchedFormData,
    originalCargo: cargo,
  });

  const getDateProps = (eventKey: UseControllerProps<SCVContainerFormValues>['name']) => {
    return {
      ...buildEventProps({
        deleteActor: addDeletedEvent,
        unDeleteActor: removeDeletedEvent,
        eventValidationRanges,
        eventKey,
      }),
    };
  };

  return (
    <PageLayout>
      {contextHolder}
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit((formData) => {
            if (cargo && cargoInFormData && isDirty) {
              patchCargoData({
                patchCargoDTO: handleCargoFormSubmit(cargo, formData, dirtyFields, deletedEventIds, Mode.Air),
              });
              reset({}, { keepValues: true });
            }
          })}
        >
          <ScvContainerDetailsHeader
            mode={Mode.Air}
            containerId={cargo.carrierShipmentId}
            data={cargoInFormData}
            customer={customer}
            isSubmitDisabled={isLoadingPatchCargoData || !canSubmit || !isDirty}
            isDeleting={false}
            updateManualTracking={updateManualTracking}
            isUpdatingTracking={isUpdatingCTATracking}
            setJSONModalOpen={setJSONModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
            setPurgeModalOpen={setPurgeModalOpen}
          />
          <p>
            {cargo.carrierShipmentId}
            <br />
            {cargo.id}
          </p>
          {isError && (
            <ErrorWrapper>
              <Alert
                message='Error while getting air cargo data'
                showIcon
                description={error?.toString()}
                type='error'
              />
            </ErrorWrapper>
          )}
          {patchCargoDataError && (
            <ErrorWrapper>
              <Alert
                message='Error updating the air cargo'
                showIcon
                description={`Error: ${patchCargoDataError?.message}`}
                type='error'
              />
            </ErrorWrapper>
          )}
          {deleteCargoDataError && (
            <ErrorWrapper>
              <Alert
                message='Error while deleting this cargo'
                showIcon
                description={`Error: ${deleteCargoDataError?.message}`}
                type='error'
              />
            </ErrorWrapper>
          )}
          {updateCTATrackingError && (
            <ErrorWrapper>
              <Alert
                message="CTA: There was an error updating the container's tracking status."
                showIcon
                description={`Error: ${updateCTATrackingError?.message}`}
                type='error'
              />
            </ErrorWrapper>
          )}
          {!canSubmit && (
            <ErrorWrapper>
              <Alert message="Can't load/submit cargo" showIcon description='MAWB is missing' type='error' />
            </ErrorWrapper>
          )}

          <FormSection>
            <CargoValidationErrorSection formMethods={formMethods} originalCargo={cargo} />
            <Timeline>
              <CDetailsTimelineItem dot={<CDetailsTimelinePoint />}>
                <CDetailsCard>
                  <Box display='flex' flexAlign='row' justifyContent='space-between' width='100%'>
                    <Box flexBasis='25%'>
                      <FormLabel htmlFor='status'>Status</FormLabel>
                      <Controller
                        control={control}
                        name='status'
                        render={({ field }) => <Input {...field} disabled id='status' />}
                      />
                    </Box>
                    <Box flexBasis='25%'>
                      <CarrierSearch name='carrier' label='Airline Name' mode={Mode.Air} />
                    </Box>
                    <Box flexBasis='10%'>
                      <FormLabel htmlFor='pieces'>Pieces</FormLabel>
                      <Controller
                        control={control}
                        name='pieces'
                        render={({ field }) => <Input {...field} disabled />}
                      />
                    </Box>
                    <Box flexBasis='10%'>
                      <FormLabel htmlFor='weight'>Weight - {cargo?.weightUnit || WeightUnit.Kgs}</FormLabel>
                      <Controller
                        control={control}
                        name='weight'
                        render={({ field }) => <Input {...field} disabled />}
                      />
                    </Box>
                    <Box flexBasis='25%'>
                      <FormLabel htmlFor='freightForwarderName'>Freight Forwarder</FormLabel>
                      <Controller
                        control={control}
                        name='freightForwarderName'
                        render={({ field }) => <Input {...field} />}
                      />
                    </Box>
                  </Box>
                </CDetailsCard>
              </CDetailsTimelineItem>

              <CDetailsTimelineItem dot={<TimelineMilestoneIcon />}>
                <CDetailsCard>
                  <CDetailsCardContentLayout>
                    <PortSearch label='Airport of Loading' name='departurePort' type={LocationType.Airport} />
                    <ScvContainerDate label='Booking Confirmed' {...getDateProps('bookingConfirmed.actualDate')} />
                    <ScvContainerDate label='Cargo Received' {...getDateProps('receive.actualDate')} />
                  </CDetailsCardContentLayout>
                </CDetailsCard>
              </CDetailsTimelineItem>

              <CDetailsTimelineItem dot={<TimelineMilestoneIcon />}>
                <ShipmentDates mode={Mode.Air} getDateProps={getDateProps} />
              </CDetailsTimelineItem>

              <TranshipmentFieldArray getDateProps={getDateProps} mode={Mode.Air} />

              <CDetailsTimelineItem dot={<TimelineMilestoneIcon />}>
                <CDetailsCard>
                  <CDetailsCardContentLayout>
                    <PortSearch label='Airport of Discharge' name='arrivalPort' type={LocationType.Airport} />
                    <ScvContainerDate label='Cargo Collected' {...getDateProps('collect.actualDate')} />
                  </CDetailsCardContentLayout>
                </CDetailsCard>
              </CDetailsTimelineItem>
            </Timeline>
          </FormSection>
          <ContainerDeleteModal
            isOpen={isDeleteModalOpen}
            containerNumber={cargo?.container?.containerNumber || ''}
            customer={customer?.fullCompanyName || customer?.customerId || ''}
            setDeleteModalOpen={setDeleteModalOpen}
            onCancel={() => setDeleteModalOpen(false)}
            onDelete={() => {
              if (cargoInFormData) {
                deleteCargoData({
                  deleteCargoDTO: handleCargoAirDelete(cargoInFormData),
                });
                setDeleteModalOpen(false);
              }
            }}
          />
          <ShipmentPurgeModal
            isOpen={isPurgeModalOpen}
            shipmentNumbers={[cargoInFormData?.mawb || '']}
            customer={customer?.fullCompanyName || customer?.customerId || ''}
            onCancel={() => setPurgeModalOpen(false)}
            onPurge={() => {
              if (cargoInFormData) {
                purgeShipmentData();
              }
            }}
          />

          <Modal
            title='Cargo JSON'
            visible={isJSONModalOpen}
            width={800}
            centered
            onCancel={() => setJSONModalOpen(false)}
            footer={null}
          >
            <Box pt={2} pb={4}>
              <Input.TextArea rows={15} value={JSON.stringify(cargo, null, 4)} />
            </Box>
          </Modal>
          <DevTool control={control} placement='top-right' />
        </form>
      </FormProvider>
    </PageLayout>
  );
};

export default AirContainer;
